import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule, Routes } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { HttpClientInMemoryWebApiModule } from 'angular-in-memory-web-api';
import { FakeDbService } from '@fake-db/fake-db.service';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import 'hammerjs';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgScrollbarModule } from 'ngx-scrollbar';

import { ToastrModule } from 'ngx-toastr';
import { TranslateModule } from '@ngx-translate/core';
import { CoreModule } from '@core/core.module';
import { CoreCommonModule } from '@core/common.module';
import { CoreSidebarModule } from '@core/components';

import { coreConfig } from 'app/app-config';
import { AuthGuard } from 'app/shared/auth/auth.guards';
import { fakeBackendProvider } from 'app/shared/auth'; // used to create fake backend
import { JwtInterceptor, ErrorInterceptor } from 'app/shared/auth';
import { AppComponent } from 'app/app.component';
import { LayoutModule } from 'app/shared/layouts/layout.module';
import { ContentHeaderModule } from 'app/shared/page-layout-module/content-header/content-header.module';
import { DndDirective } from './shared/directives/dnd.directive';
import { GoogleLoginProvider, GoogleSigninButtonDirective, } from '@abacritt/angularx-social-login';
import { SocialAuthServiceConfig } from '@abacritt/angularx-social-login';
import { DemoMaterialModule } from './material';
import { FormValidationErrorsComponent } from './shared/components/form-validation-errors/form-validation-errors.component';
import { LOAD_WASM, NgxScannerQrcodeModule } from 'ngx-scanner-qrcode';
import { DatePipe } from '@angular/common';
// import { TaxIdFormatterPipe } from './tax-id-formatter.pipe';
//import { SafePipe } from './modules/user-linx/donations/safe.pipe';

LOAD_WASM().subscribe((res: any) => {
  console.log('LOAD_WASM',res)
})

// import { NgApexchartsModule } from 'ng-apexcharts';
const appRoutes: Routes = [

  {
    path: 'linx',
    loadChildren: () => import('./modules/linx.module').then(m => m.LinxModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'onBoarding',
    //loadChildren: () => import('./modules/linx/onboard.module').then(m => m.OnboardModule),
    loadChildren: () => import('./modules/linx.module').then(m => m.LinxModule),
  },
  {
    path: 'user-account',
    loadChildren: () => import('./modules/user-account/user-account.module').then(m => m.UserAccountModule)
  },

  {
    path: 'components',
    loadChildren: () => import('./shared/components/components.module').then(m => m.ComponentsModule),
    canActivate: [AuthGuard]
  },
  //{
    //path: 'forms',
    //loadChildren: () => import('./modules/formsBCK/forms.module').then(m => m.FormsModule),
    //canActivate: [AuthGuard]
  //},
  {
    path: '',
    redirectTo: 'linx/user-linx/dashboard',
    pathMatch: 'full'
  },
  {
    path: '',
    redirectTo: '/linx/campaigns',
    pathMatch: 'full'
  },
  
  {
    path: '**',
    redirectTo: '/user-account/miscellaneous/error' //Error 404 - Page not found
  }
];

@NgModule({
  declarations: [
    AppComponent,
    DndDirective,
    // TaxIdFormatterPipe,
    //SafePipe
  ],

  exports: [],
  imports: [
    DemoMaterialModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    NgScrollbarModule,
    HttpClientInMemoryWebApiModule.forRoot(FakeDbService, {
      delay: 0,
      passThruUnknownUrl: true
    }),
    RouterModule.forRoot(appRoutes, {
      scrollPositionRestoration: 'enabled',
      useHash: true
    }),
    NgbModule,
    ToastrModule.forRoot(),
    TranslateModule.forRoot(),
    CoreModule.forRoot(coreConfig),
    CoreCommonModule,
    CoreSidebarModule,
    LayoutModule,
    ContentHeaderModule,
    NgxScannerQrcodeModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    {
      provide: STEPPER_GLOBAL_OPTIONS, useValue: { displayDefaultIndicatorType: false }
    },
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    // NgApexchartsModule,

    // ! IMPORTANT: Provider used to create fake backend, comment while using real API
    fakeBackendProvider,

    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              '242628204425-r3r070a8aosnsu0nll0vp8qkearfft4r.apps.googleusercontent.com'
            )
          }
        ],
        onError: (err) => {
          console.error(err);
        }
      } as SocialAuthServiceConfig,

    },
    DatePipe,
    GoogleSigninButtonDirective,

  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  hmr: false,
  //apiUrl: 'https://yog.uatdomain.xyz/Api/Api/',
  //imageUrl: 'https://yog.uatdomain.xyz/Api/',
  //  apiUrl: 'https://yog.uatdomain.xyz/DevApi/Api/',
  // imageUrl: 'https://yog.uatdomain.xyz/devapi/',
  /*apiUrl: 'http://13.235.24.235/OJCAPI/api',
  imageUrl: 'http://13.235.24.235/OJCAPI/',*/
  apiUrl: 'https://ojclinx.trigma.in/ojcapi/api',
  imageUrl: 'https://ojclinx.trigma.in/ojcapi/',
  apiUrl2: 'https://ojclinx.trigma.in/ojcapiv8/api',
  //apiUrl: 'https://ojclinx.trigma.in/ojcapiV8/api',
  //imageUrl: 'https://ojclinx.trigma.in/ojcapiV8/',

  AccountSetup: {
    togetStartedmessage: "To get started, please enter the Tax ID of your organization",
    welcomeTo: "Welcome to OJC Linx!",
    pleaseHoldMessage: "Please hold on for a moment while we retrieve your data from our partners.",
    CouldnotfindMessage: "We could not locate your organization details, please provide more details.",
    PleaseConfirmOnOrgDetails: "Please confirm your organization details",
    LooksGoodButton: "Looks good, continue",
    setupLater: "Set up later",
    continueSetup: "Continue account setup",
    ImportDonorText: "Import Donors",
    ContinueLater: "Continue later",
    continue: "Continue",
    edit: "Edit",
    save: "Save",
    StartNow: "Start now",
    connectionSetup: "Connection Setup",
    Done: "Done",
    setupAccountwithSpace: "Setup Account",
    orgDetails: "Org Detail",
    DropFileOr: "Drop CSV file here",
    add: "Add",
    skip: "Skip",
    upload: "Upload file",
    Addlocation: "Add location",
    Addcontact: "Add contact",
    addPhoneNum:"Add number",
    addEmail:"Add Email",
    Setupconnections: "Setup Connection"
  },
  CampaignPage: {
    Donations: "Donations",
    Donors: "Donors",
    Collected: "Collected",
    Pledges: "Pledges / Schedules",
    validStartDate: "Start date should be greater then or equal to present date",
    validStartTime: "Start time should be greater then or equal to present time",
    requiredFieldMessage: "Fill the required fields",
    timeShouldBeAfterMessage: "End Date & time should not be less than Start Date & time",
    CreateNewCampaingMessage: "Create a new campaign",
    CreateNewCampaingEdit: "Campaign settings"
  },

  userlistPage: {
    Trending: "Trending",
    Newdonors: "New Donors",
    Donations: "Donations",
    Importdonors: "Import donors",
    CreateNewGroup: "Create New Group"

  },

  Donationspage: {
    Trending: "Trending",
    Upcoming: "Upcoming",
    Donations: "Donations",
    Pledges: "Pledges",
    Certificates: "Certificates",
    Followups: "Follow-ups",
    NewDonation: "New Donation",
    Donationdetails: "Donation details"

  },

  Dashboard: {
    Newdonors: "New Donors",
    Trending: "Trending",
    Donationscount: "Donations count",
    Donations: "Donations",
    Devices: "Devices",
    TopCampaigns: "Top Campaigns",
    Followup: "Follow up",
    Schedules: "Schedules",
    SourceofFunds: "Source of Funds",
    Mostgivingtime: "Most giving time",
    PledgestoDonations: "Pledges to Donations",
    Certificates: "Certificates",
    Pledges: "Pledges / Schedules",
    Donors: "Donors",
    Collected: "Collected",
    Pledge: "Pledges ",
  },
  forgotpass: {
    Password: "Forgot password",
    recovermessage: "Please enter your email address to recover your password",
  },
  Resetpage: {
    Reset: "Reset your password",
    create: "Please create your new password",
  },

  singnup: {
    Signup: "Sign up",
    Already: "Already have an account?",
  },
  login: {
    Login: "Sign in",
    account: "Don't have an account?",
  },

  CommonMessage: {
    invalidInputs: "Invalid Inputs"
  }

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.

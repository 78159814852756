import { Injectable } from '@angular/core';
import { HttpClient, HttpClientModule, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { environment } from 'environments/environment';
@Injectable({
  providedIn: 'root'
})
export class DataService {

  send_ReceiptData = new Subject<any>();
  send_DonorData = new BehaviorSubject<any>({});
  update_DonationData = new Subject<any>();//update donation dashboard after successfull donation
  donor_Donation = new Subject<any>();//open donation popup from donor detail popup
  update_DonorList = new Subject<any>();//update donor list in donor dashboard when donor is created from donation popup
  update_Dashboard = new Subject<any>();
  update_OrgInfo = new Subject<any>();
  send_Org = new Subject<any>();
  send_donotdata= new Subject<any>();
  send_accountdetails= new Subject<any>();
  send_taxid= new BehaviorSubject<any>({});
  private send_DonordataNew = new BehaviorSubject<any>({});
  send_DonordataNew$ = this.send_DonordataNew.asObservable();

  updateDonordataNew(newValue:any) {
    this.send_DonordataNew.next(newValue);
  }
  constructor(private http: HttpClient) { }


  SignIn(data: any): Observable<any> {
    return this.http.post(environment.apiUrl + "/AccountSetup/SignIn", data);
  }


  ValidateSocial(data: any): Observable<any> {
    return this.http.post(environment.apiUrl + "/AccountSetup/SignUpBySocials", data);
  }


  getLocations(data: any): Observable<any> {
    return this.http.post(environment.apiUrl + "/Locations/GetList", data);
  }


  getContacts(data: any): Observable<any> {
    return this.http.post(environment.apiUrl + "/Contacts/GetList", data);
  }

  getBasicDetails(data: any): Observable<any> {
    return this.http.post(environment.apiUrl + "/Organization/OrganizationData", data);
  }

  SaveTemplateAPI(data: any): Observable<any> {
    return this.http.post(environment.apiUrl + "/Donors/SaveTemplate", data);
  }


  updateOnboardingStatus(data: any): Observable<any> {
    return this.http.post(environment.apiUrl + "/Organization/UpdateOnBoardingStatus", data);
  }

  updateBasicInfo(data: any): Observable<any> {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    return this.http.post(environment.apiUrl + "/Organization/UpdateOrganization", data, { headers });
  }

  UploadDonorsfile(data: any): Observable<any> {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    return this.http.post(environment.apiUrl + "/Donors/UploadDonorsExcel", data, { headers });
  }

  UploadDonorsfileCSV(data: any): Observable<any> {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    return this.http.post(environment.apiUrl + "/Donors/UploadDonorsExcelCSV", data, { headers });
  }

  SubmitLocation(data: any, type): Observable<any> {
    if (type == "Add") {
      return this.http.post(environment.apiUrl + "/Locations/SubmitLocation", data);
    } else {
      return this.http.post(environment.apiUrl + "/Locations/UpdateLocation", data);
    }

  }
  ImportDonortemplate(data: any): Observable<any> {
    return this.http.post(environment.apiUrl + "/Donors/ImportDonor", data);
  }

  importData(data: any): Observable<any> {
    return this.http.post(environment.apiUrl + "/Donors/ImportDonor", data);
  }

  importdataCSV(data: any): Observable<any> {
    return this.http.post(environment.apiUrl + "/Donors/ImportDonorCSV", data);
  }

  saveTemplateNow(data: any): Observable<any> {
    return this.http.post(environment.apiUrl + "/Donors/SaveTemplate", data);
  }

  getTemplate(data: any): Observable<any> {
    return this.http.post(environment.apiUrl + "/Donors/GetTemplate", data);
  }



  GetDonorNote(data: any): Observable<any> {
    return this.http.post(environment.apiUrl + "/Donors/GetDonorNote", data);
  }

  AddDonorNote(data: any): Observable<any> {
    return this.http.post(environment.apiUrl + "/Donors/AddDonorNote", data);
  }

  getDonorDetailbyId(data: any): Observable<any> {
    return this.http.post(environment.apiUrl + "/Donors/GetDonorById", data);
  }

  getUserDetailsByTaxID(taxid) {
    // const headers = new HttpHeaders();
    const username = 'dev_api';
    const password = 'eVK32h1bDt3n';
    const base64Credentials = btoa(username + ':' + password);

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Basic ' + base64Credentials
    });
    // const base64Credentials = btoa(username + ':' + password);

    return this.http.get("https://conveyservice.uatdomain.xyz/api/settings/ojcrequest.php?ojc=" + taxid, { headers });
    //return this.http.get(environment.apiUrl + "/settings/ojcrequest.php?ojc=" + taxid, { headers });
  }
  // getUserDetailsByTaxID(data:any) {
  //   return this.http.get(environment.apiUrl2 + "/BatchProcessing/GetOrganizationAccountsByTaxId?taxId="+data);
  // }
  // getdatabytaxidd(data: any): Observable<any> {
  //   const username = 'dev_api';
  //   const password = 'eVK32h1bDt3n';
  //   const base64Credentials = btoa(username + ':' + password);

  //   const headers = new HttpHeaders({
  //     'Content-Type': 'application/json',
  //     'Authorization': 'Basic ' + base64Credentials
  //   });
  //   //debugger;

  //   return this.http.get("https://api.ojcfund.org:3301/api/Organizations/0?taxID=876543213", { headers });
  // }

  ConnectionList(data: any): Observable<any> {
    return this.http.post(environment.apiUrl + "/Connections/GetList", data);
  }

  UpdateConnection(data: any): Observable<any> {
    return this.http.post(environment.apiUrl + "/Connections/SubmitConnection", data);
  }

  AddContact(data: any): Observable<any> {
    return this.http.post(environment.apiUrl + "/Contacts/SubmitContact", data);
  }




  ValidateSocialsSignup(data: any): Observable<any> {
    return this.http.post(environment.apiUrl + "/AccountSetup/SignUpBySocials", data);
  }

  // AccountSetup/SignUp


  Signup(data: any): Observable<any> {
    return this.http.post(environment.apiUrl + "/AccountSetup/SignUp", data);
  }


  // DataServices AccountSetup/ForgotPassword

  forgetPassword(data: any): Observable<any> {
    return this.http.post(environment.apiUrl + "/AccountSetup/ForgotPassword", data);
  }


  // AccountSetup/UpdatePassword


  UpdatePassword(data: any): Observable<any> {
    return this.http.post(environment.apiUrl + "/AccountSetup/UpdatePassword", data);
  }

  VerifyUser(data: any): Observable<any> {
    return this.http.post(environment.apiUrl + "/AccountSetup/VerifyAccount", data);
  }


  // Campaigns/InsertCampaign

  InsertCampaign(data: any): Observable<any> {
    return this.http.post(environment.apiUrl + "/Campaigns/InsertCampaign", data);
  }

  // Campaigns/GetList

  GetList(data: any): Observable<any> {
    return this.http.post(environment.apiUrl + "/Campaigns/GetList", data);
  }

  // Campaigns/UpdateCampaign

  UpdateCampaign(data: any): Observable<any> {
    return this.http.post(environment.apiUrl + "/Campaigns/UpdateCampaign", data);
  }

  // /Campaigns/DeleteCampaign
  DeleteCampaign(data: any): Observable<any> {
    return this.http.post(environment.apiUrl + "/Campaigns/DeleteCampaign", data);
  }


  // Donors/AddBasicInfo

  AddBasicInfo(data: any): Observable<any> {
    return this.http.post(environment.apiUrl + "/Donors/AddBasicInfo", data);
  }


  UpdateBasicInfo(data: any): Observable<any> {
    return this.http.post(environment.apiUrl + "/Donors/UpdateBasicInfo", data);
  }


  CreateGroup(data: any): Observable<any> {
    return this.http.post(environment.apiUrl + "/Donors/CreateDonorGroup", data);
  }


  Getgrouplist(data: any): Observable<any> {
    return this.http.post(environment.apiUrl + "/Donors/GeDonorGroupByOrganizationId", data);
  }
  Deletgroup(data: any): Observable<any> {
    return this.http.post(environment.apiUrl + "/Donors/DeleteDonorGroup", data);
  }

  overhallapi(data: any): Observable<any> {
    return this.http.post(environment.apiUrl + "/Donors/GetDonorOverAll", data);
  }

  updategrouplist(data: any): Observable<any> {
    return this.http.post(environment.apiUrl + "/Donors/UpdateDonorGroup", data);
  }
  AddAdditionalInfo(data: any): Observable<any> {
    return this.http.post(environment.apiUrl + "/Donors/AddAdditionalInfo", data);
  }

  // Donors/GetDonorList
  updateAdditionalInfo(data: any): Observable<any> {
    return this.http.post(environment.apiUrl + "/Donors/UpdateAdditionalInfo", data);
  }

  GetDonorList(data: any): Observable<any> {
    return this.http.post(environment.apiUrl + "/Donors/GetDonorList", data);
  }
  // Donors/GetDonorListWithFilters


  GetDonorListWithFilters(data: any): Observable<any> {
    return this.http.post(environment.apiUrl + "/Donors/GetDonorListWithFilters", data);
  }
  // donation page
  adddonorfromdonation(data: any): Observable<any> {
    //return this.http.post(environment.apiUrl + "/Donations/AddDonationDonor", data);
    // return this.http.post(environment.apiUrl + "/Donors/AddBasicInfo", data);
    return this.http.post(environment.apiUrl + "/Donations/AddDonationDonor", data);
  }
  donorlistfordononation(data: any): Observable<any> {
    return this.http.post(environment.apiUrl + "/Donations/GetDonationDonorListWithFilters", data);
  }

  AddDonation(data: any): Observable<any> {
    return this.http.post(environment.apiUrl + "/Donations/AddDonation", data);
  }

  AddCheckDonation(data: any): Observable<any> {
    return this.http.post(environment.apiUrl + "/Donations/AddCheckDonation", data);
  }

  DonationsData(data: any): Observable<any> {
    return this.http.post(environment.apiUrl + "/Donations/DonationsData", data);
  }

  getDonationList(data: any): Observable<any> {
    return this.http.post(environment.apiUrl + "/Donations/GetDonationList", data);
  }

  getTopCampaignList(data: any): Observable<any> {
    return this.http.post(environment.apiUrl + "/Donations/GetOrganizationTopCampaigns", data);
  }

  getTrendList(data: any): Observable<any> {
    return this.http.post(environment.apiUrl + "/Donations/GetOrganizationTrends", data);
  }

  getFollowAndScheduleList(data: any): Observable<any> {
    return this.http.post(environment.apiUrl + "/Donations/GetOrganizationDashboardDonations", data);
  }

  getAllChartData(data: any): Observable<any> {
    return this.http.post(environment.apiUrl + "/Donations/GetCharts", data);
  }

  getCampaignOtherDetails(data: any): Observable<any> {
    return this.http.post(environment.apiUrl + "/Campaigns/GetCampaignDetailsOtherData", data);
  }
  savePledge(data: any): Observable<any> {
    return this.http.post(environment.apiUrl + "/Donations/SavePledge", data);
  }
  getPledgeList(data: any): Observable<any> {
    return this.http.post(environment.apiUrl + "/Donations/PledgeList", data);
  }
  // setRecurringDonation(data: any): Observable<any> {
  //   return this.http.post(environment.apiUrl + "/Donations/SaveRecurringDonation", data);
  // }
  sendSMS(data: any): Observable<any> {
    return this.http.post(environment.apiUrl + "/Donations/SendConfirmationOnSMS", data);
  }
  sendEmail(data: any): Observable<any> {
    return this.http.post(environment.apiUrl + "/Donations/SendReceiptWithEmail", data);
  }
  printReciept(data: any): Observable<any> {
    return this.http.post(environment.apiUrl + "/Donations/PrintReceipt", data);
  }
  scanCertificateStatus(data: any): Observable<any> {
    const username = 'dev_api';
    const password = 'eVK32h1bDt3n';
    const base64Credentials = btoa(username + ':' + password);

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Basic ' + base64Credentials
    })

    return this.http.get("https://api.ojcfund.org:3301/api/CharityPortal/GetVoucherInfo?scannedBarcode=" + data, { headers });
  }
  validateCertificateDetails(data: any): Observable<any> {
    return this.http.post(environment.apiUrl + "/Donations/ValidateCertificateDetails", data);
  }

  saveCertificateDetails(data: any): Observable<any> {
    return this.http.post(environment.apiUrl + "/Donations/SaveCertificateDetails", data);
  }

  addCardDonation(data: any): Observable<any> {
    return this.http.post(environment.apiUrl + "/Donations/AddCardDonation", data);
  }

  addRecurringCardDonation(data: any): Observable<any> {
    return this.http.post(environment.apiUrl + "/Donations/SaveRecurringDonation", data);
  }

  getDonorCardList(data) {
    return this.http.post(environment.apiUrl + "/Donations/CardList", data);
  }

  saveCard(data) {
    return this.http.post(environment.apiUrl + "/Donations/SaveCard", data);
  }

  deleteCard(data) {
    return this.http.post(environment.apiUrl + "/Donations/DeleteCard", data);
  }

  getDonationDetail(data) {
    return this.http.post(environment.apiUrl + "/Donations/GetDonationDetailByStatusAndId", data);
  }

  saveDafImage(data) {
    return this.http.post(environment.apiUrl + "/Donations/UpdateCertificateImage", data);
  }

  saveFollowUp(data) {
    return this.http.post(environment.apiUrl + "/Donations/SaveFollowupDetails", data);
  }
  updatelocation(data: any): Observable<any> {
    return this.http.post(environment.apiUrl2 + "/Locations/UpdateLocation", data);
  }
  newLocation(data: any): Observable<any> {
    return this.http.post(environment.apiUrl2 + "/Locations/SubmitLocation", data);
  }
  fetchlocation(data: any): Observable<any> {
    return this.http.post(environment.apiUrl2 + "/Locations/GetList", data);
  }
  getTaxIdFromLocalStorage(): string {
    const currentUserTaxIdData = localStorage.getItem('Organization');
    if (!currentUserTaxIdData) {
      return null;
    }
    const currentUserTaxIdObject = JSON.parse(currentUserTaxIdData);
    const currentUserTaxId = currentUserTaxIdObject.TaxId;
    // const currentUserTaxId = "258963741";
    return currentUserTaxId;
  }
  organizationData: any;
  taxid: any;
  gettaxid() {
    const storedData = localStorage.getItem('Organization');
    if (storedData) {
      this.organizationData = JSON.parse(storedData);
      this.taxid = this.organizationData.TaxId;
      // this.taxid = '258963741'
      if (this.taxid) {
        this.getUserDetailsByTaxID(this.taxid).subscribe({
          next: (res: any) => {
            this.send_accountdetails.next(res);
            localStorage.setItem('accountinformation', JSON.stringify(res));
          },
          error: (error: any) => {
            console.error('Error fetching data by tax ID:', error);
          },
        });
      } else {
        console.error('Tax ID is not found in the stored data.');
      }
    } else {
      console.error('No organization data found in local storage.');
    }
  }
  account: any;
  idacc: any;
  currentUsercirtificateData: any;

  settaxid() {
    const accountinformation = localStorage.getItem('accountinformation');
    this.account = this.account || {};
    if (accountinformation) {
      try {
        this.account = JSON.parse(accountinformation);
  
        if (this.account&& this.account[0]?.BankAccounts?.length) {
          const bankAccounts = this.account[0].BankAccounts;
          const defaultAccount = bankAccounts.find(bankAccount => bankAccount.IsDefault);
          const activeAccount = defaultAccount || bankAccounts.find(bankAccount => bankAccount.IsActive);
          this.idacc = activeAccount || '';
          return this.idacc;
        } else {
          this.idacc = '';
        }
      } catch (error) {
        console.error('Error parsing account information:', error);
        this.idacc = '';
      }
    } else {
      this.account = null; 
      this.idacc = '';
    }
  }
  returnallaccountdata() {
    const accountinformation = localStorage.getItem('accountinformation');
    if (accountinformation) {
      this.account = JSON.parse(accountinformation);
      return this.account;
    }
  }
  getAllcertificate(data: any) {
    return this.http.post(environment.apiUrl2 + '/BatchProcessing/GetAllCertificatesByCertficateNo', data);
  }
  getOrganizationIdFromLocalStorage(): number | null {
    const storedData = localStorage.getItem('Organization');
    if (!storedData) {
      return null; // or a default value, or throw an error
    }
    const storedObject = JSON.parse(storedData);
    // alert(storedObject.OrganizationId);
    return storedObject.OrganizationId || null;
  }
  getvalidatecirtificateDatafromLocalstorage(): any {
    this.currentUsercirtificateData = '';
    this.currentUsercirtificateData = localStorage.getItem('validateSavedCertificatesBatchData');
    if (!this.currentUsercirtificateData) {
      return null;
    }
    const currentUsercirtificateDataObject = this.currentUsercirtificateData;

    if (currentUsercirtificateDataObject) {
      return currentUsercirtificateDataObject;
    }
    // const currentUserCirtificateData = currentUsercirtificateDataObject.TaxId;

  }
  updatelocationbatch(data: any): Observable<any> {
    return this.http.post(environment.apiUrl + "/Locations/UpdateLocation", data);
  }
  updateCertificateImageBatchApiupload(data: any) {
    return this.http.post(environment.apiUrl2 + "/BatchProcessing/UpdateCertificateImageBatch", data);
  }
  updateCertificateImageBatchApi(certificateId: number, voucherImage: string, status: string): Observable<any> {
    const url = `${environment.apiUrl2}/BatchProcessing/UpdateCertificateImageBatch?certificateId=${certificateId}&voucherImage=${voucherImage}&status=${status}`;
    return this.http.put(url, null); // Pass null as the body if your PUT request doesn't require one
  }
  validateCertificateBatch(data: any): Observable<any> {
    return this.http.post(environment.apiUrl + "/Donations/ValidateCertificateDetails", data);
  }
  validateSavedCertificates(organizationId: number, certificateNo: string): Observable<any> {
    const url = `${environment.apiUrl2}/BatchProcessing/ValidateSavedCertificates?organizationId=${organizationId}&certificateNo=${certificateNo}`;
    return this.http.get(url);
  }

  saveCertificate(donorId: number, certificateNumber: string, amount: number): Observable<any> {
    const url = `${environment.apiUrl2}/BatchProcessing/ValidateManualCertificate?donorId=${donorId}&certificateNo=${certificateNumber}&amount=${amount}`;
    return this.http.get(url);
  }
  processcertificates(data: any) {
    return this.http.post(environment.apiUrl2 + '/BatchProcessing/ProcessCertificateBatch', data);
  }
  addBatchDonationapi(data: any) {
    return this.http.post(environment.apiUrl2 + '/Donors/AddBatchDonation', data);
  }
  UpdateCertificateImageBatch(data: any) {
    return this.http.post(environment.apiUrl2 + '/BatchProcessing/UpdateCertificateImageBatch', data);
  }
  editUser(data: any): Observable<any> {
    return this.http.post('https://ojclinx.trigma.in/ojcapiv8/api/Settings/EditUser', data);
  }
  addUser(data: any): Observable<any> {
    return this.http.post('https://ojclinx.trigma.in/ojcapiv8/api/Settings/AddUser', data);
  }
  getUser(organizationId: any, role: any, keywords: any): Observable<any> {
    let queryParams = [];
    if (organizationId) {
      queryParams.push(`organizationId=${encodeURIComponent(organizationId)}`);
    }
    if (role) {
      queryParams.push(`role=${encodeURIComponent(role)}`);
    }
    if (keywords) {
      queryParams.push(`keywords=${encodeURIComponent(keywords)}`);
    }
    const queryString = queryParams.length ? '?' + queryParams.join('&') : '';
    return this.http.get('https://ojclinx.trigma.in/ojcapiv8/api/Settings/GetAllUsers' + queryString);
  }
  getIsOrganizationFromLocalStorage(): boolean {
    const currentUserData = localStorage.getItem('userdata');
    if (!currentUserData) {
      return null;
    }
    const currentUserDataObject = JSON.parse(currentUserData);
    const IsOrganization = currentUserDataObject[0].IsOrganization;
    return IsOrganization;
  }
  getSettings(organizationId: number): Observable<any> {
    const url = `${environment.apiUrl2}/Settings/GetSettings?OrganizationId=${organizationId}`;
    return this.http.get<any>(url);
  }
  orgdata: any;
  organizationDatas: any;
  getorganizationdat() {
    this.orgdata = localStorage.getItem('Orgdata');
    this.organizationDatas = JSON.parse(this.orgdata);
    return this.organizationDatas;
  }
  userDetails:any;
  userBankaccountDetails:any;
  IsDefaultAccount:any;
  selectedAccNum:any
  showMessage:any
  selectedBankNameACC:any;
  
  getUserDetails() {
    // alert("chal gaya"+this.taxid);
    this.getUserDetailsByTaxID(this.taxid).subscribe(
      (response: any) => {
        if (response && response.length > 0) {
          this.userDetails = response;
          this.userBankaccountDetails = response[0].BankAccounts;
          // Find the default account
          const defaultAccount = this.userBankaccountDetails.find(
            (account) => account.IsDefault
          );

          if (defaultAccount) {
            this.selectedAccNum = defaultAccount.AccNum;
            this.IsDefaultAccount = true;
            this.showMessage = false;
            // this.showMessage = false; // Hide the message
          } else {
            // Find the first active account if no default is set
            const firstActiveAccount = this.userBankaccountDetails.find(
              (account) => account.IsActive
            );

            if (firstActiveAccount) {
              this.selectedAccNum = firstActiveAccount.AccNum;
              this.selectedBankNameACC = firstActiveAccount.BankName;
              this.IsDefaultAccount = false;
            }
          }
        }
      },
      (error) => {
        console.error("Error fetching user details:", error);
      }
    );
  }


  // remove dash from tax id 
  formatAndRemoveDashes(data) {
    let input = data;
    let formattedInput = '';
    if (input.length > 0) {
      formattedInput = input.substring(0, 3);
      if (input.length > 3) {
        formattedInput += `-${input.substring(3, 6)}`;
      }
      if (input.length > 6) {
        formattedInput += `-${input.substring(6, 11)}`;
      }
    }
    const noDashInput = formattedInput.replace(/-/g, '');
  
   return noDashInput;
  }

  // tax id format set for displaying 
  taxformateForDisplay(taxId: string): string {
    // Remove any non-digit characters from the input
    const digits = taxId.replace(/\D/g, '');
  
    let formattedInput = '';
  
    // Format the input based on the length
    if (digits.length >= 6 && digits.length <= 9) {
      if (digits.length <= 6) {
        // Format as XXX-XXX
        formattedInput = digits.substring(0, 3) + '-' + digits.substring(3);
      } else {
        // Format as XXX-XXX-XXX
        formattedInput = digits.substring(0, 3) + '-' +
                         digits.substring(3, 6) + '-' +
                         digits.substring(6);
      }
    } else {
      // If length is not between 6 and 9, return the original input
      formattedInput = digits;
    }
  
    return formattedInput;
  }


}
import { CoreMenu } from '@core/types';

//? DOC: 

export const menu: CoreMenu[] = [
  {
    id: 'dashboard',
    title: 'Dashboard',
    translate: 'Dashboard',
    type: 'item',
    icon: '../../../../../assets/images/dashboard-icon.png',
    iconActive: '../../../../../assets/images/dashboard-icon-active.png',
    url: 'linx/user-linx/dashboard'
  },
  {
    id: 'donations',
    title: 'Donations',
    translate: 'Donations',
    type: 'item',
    icon: '../../../../../assets/images/donation-tab-icon.png',
    iconActive: '../../../../../assets/images/donation-tab-icon-active.png',
    url: 'linx/user-linx/donations'
  },
  {
    id: 'donors',
    title: 'Donors',
    translate: 'Donors',
    type: 'item',
    icon: '../../../../../assets/images/donor-tab-icon.png',
    iconActive: '../../../../../assets/images/donor-tab-icon-active.png',
    url: 'linx/user-linx/donor-list'
  },
  {
    id: 'campaigns',
    title: 'Campaigns',
    translate: 'Campaigns',
    type: 'item',
    icon: '../../../../../assets/images/campaign-tab-icon.png',
    iconActive: '../../../../../assets/images/campaign-tab-icon-active.png',
    url: 'linx/user-linx/campaigns'
  },
  {
    id: 'devices',
    title: 'Devices',
    translate: 'Devices',
    type: 'item',
    icon: '../../../../../assets/images/devices-tab-icon.png',
    iconActive: '../../../../../assets/images/devices-tab-icon-active.png',
    url: 'linx/user-linx/devices'
  },
  {
    id: 'settings',
    title: 'Settings',
    translate: 'Settings',
    type: 'item',
    icon: '../../../../../assets/images/settings-tab-icon.png',
    iconActive: '../../../../../assets/images/settings-tab-icon-active.png',
    url: 'linx/user-linx/settings'
  }
 
  // 
];
// org
import {
  Component,
  Inject,
  OnDestroy,
  OnInit,
  ElementRef,
  Renderer2,
  AfterViewInit,
  ViewChild,
} from "@angular/core";
import { DOCUMENT } from "@angular/common";
import { Title } from "@angular/platform-browser";
import { MatStepperModule } from "@angular/material/stepper";
import { Subject } from "rxjs";
import { scan, takeUntil } from "rxjs/operators";
import { TranslateService } from "@ngx-translate/core";
// import * as Waves from "node-waves";
import { ToastrService } from "ngx-toastr";
import { Subscription } from 'rxjs';

import { CoreMenuService } from "@core/components/core-menu/core-menu.service";
import { CoreSidebarService } from "@core/components/core-sidebar/core-sidebar.service";
import { CoreConfigService } from "@core/services/config.service";
import { CoreLoadingScreenService } from "@core/services/loading-screen.service";
import { CoreTranslationService } from "@core/services/translation.service";
import { DatePipe } from '@angular/common';


import { menu } from "app/shared/constants/menu";
import {
  AbstractControl,
  FormBuilder,
  ValidatorFn,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { AuthenticationService } from "./shared/services/authentication.service";
import { DataService } from "./shared/services/data.service";
// import { Console, debug } from 'console';
import { MatStepper } from "@angular/material/stepper";

import Stepper from "bs-stepper";
import {
  NgxScannerQrcodeComponent,
  NgxScannerQrcodeService,
  ScannerQRCodeConfig,
  ScannerQRCodeResult,
  ScannerQRCodeSelectedFiles,
} from "ngx-scanner-qrcode";
import { Chart } from "chart.js";
import { Router, NavigationEnd } from "@angular/router";
import { filter } from "rxjs/operators";
import { log } from "console";
import { stringify } from "querystring";
import { event } from "jquery";

interface CardResponse {
  success: boolean;
  message: string;
  oldToken: string;
  newToken: string | null;
}

declare var $: any;
@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
  providers: [DatePipe]
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {

  stepperboolen = false;
  stepperboolen2 = false;
  stepperboolen3 = false;
  stepperboolen4 = true;
  stepperboolen5 = true;

  currentUser: any;
  private authSubscription: Subscription;
  certificatemanualEntryFormGroup: FormGroup;
  scanCode: any;
  orgnasitionId: any;
  updateCertificateImageBatch: any;
  totalcertificate: any = false;
  imageUrl: any;
  certificateId: any = "";
  voucherImage = "";
  status = "Pending";
  redirectModal: any = false;
  certificateno: any;
  certificatenofordelete: any;
  qrCodeResultmy: any;
  showMessage: boolean;
  IsDefaultAccount: boolean;
  checkScanStatus: any;
  //
  checkconfirm: any = false;
  uploadmodal: any = true;
  donorSearchTerm = "";
  donorsList: any[] = []; // Assuming you have a list of donors
  filteredDonors: any[] = [];
  //
  vocuherStatusBatch: any;
  vocuherImageStatusBatch: any;
  selectedAccNum: string = "";
  userBankaccountDetails: any[] = [];
  userDetails: any[] = [];
  currentUserTaxId: string;
  certificateItems: any[] = [];
  coreConfig: any;
  menu: any;
  defaultLanguage: "en"; // This language will be used as a fallback when a translation isn't found in the current language
  appLanguage: "en"; // Set application default language i.e fr

  homephoneExists:any;

  


  // certificateItems :any = [];





  // Private
  private _unsubscribeAll: Subject<any>;
  newdonorform: FormGroup;
  public fourthgroup: FormGroup;
  /**
   * Constructor
   *
   * @param {DOCUMENT} document
   * @param {Title} _title
   * @param {Renderer2} _renderer
   * @param {ElementRef} _elementRef
   * @param {CoreConfigService} _coreConfigService
   * @param {CoreSidebarService} _coreSidebarService
   * @param {CoreLoadingScreenService} _coreLoadingScreenService
   * @param {CoreMenuService} _coreMenuService
   * @param {CoreTranslationService} _coreTranslationService
   * @param {TranslateService} _translateService
   */

  firstFormGroup = this._formBuilder.group({
    firstCtrl: ["", Validators.required],
  });
  onchecksubmitmodal: any = true;
  submittedform: boolean;
  submitNewCardform: boolean;
  submitEditCardform: boolean;
  orgid: number;
  loading: boolean;
  Campaign: any;
  Campaigndropdown;
  CampaignName;
  selectedCampaign = null;
  selectedDaf = null;
  certificateImage = null;
  scanCertificateNum = null;
  showScannerDonor: boolean = false;
  scannerDonorList = null;
  scanCertificateFlag: boolean = false;
  donorslist: any;
  // stepperboolen = false;
  NewDonationAgaiondAmpount: FormGroup;
  locations: any = "";
  paymentType = null; //cash, check, card, pledge
  currentDate = null;
  currentTime = null;
  pledgeMinDueDate;
  pledgeDueDate = null;
  pledgeFollowupDate = null;
  currentUserCirtificateAllDataObject: any = "";
  pledgeList = [];
  DepositToBankAccountID: any;
  account: any;
  dataaccount: any;
  receivedData: any;
  donationIdcampaign: any;
  receivedDonor: any;
  dtadonor: any;
  orgdata: any;
  done() { }
  newCardForm: FormGroup;
  editCardForm: FormGroup;
  constructor(
    @Inject(DOCUMENT) private document: any,
    private _title: Title,
    private _renderer: Renderer2,
    private _elementRef: ElementRef,
    public _coreConfigService: CoreConfigService,
    private _coreSidebarService: CoreSidebarService,
    private _coreLoadingScreenService: CoreLoadingScreenService,
    private _coreMenuService: CoreMenuService,
    private _coreTranslationService: CoreTranslationService,
    private _translateService: TranslateService,
    private _formBuilder: FormBuilder,
    private _authenticationService: AuthenticationService,
    private service: DataService,
    private _toastrService: ToastrService,
    private qrcode: NgxScannerQrcodeService,
    private router: Router,
    private datePipe: DatePipe
  ) {
    const dtToday = new Date();
    const month = (dtToday.getMonth() + 1).toString().padStart(2, "0");
    const day = (dtToday.getDate() + 1).toString().padStart(2, "0");
    const year = dtToday.getFullYear();

    this.authSubscription = this._authenticationService.currentUser.subscribe(
      user => {
        this.currentUser = user;
        if (user) {
          console.log('Current user updated:', user);
          // alert(JSON.stringify(user));
        }
      }
    );

    // this.service.send_donotdata.subscribe((data) => {
    //   this.receivedDonor = data;
    //   this.dtadonor = this.receivedDonor[0]
    //   console.log('this.receivedData', this.dtadonor);

    // });
    this.service.send_taxid.subscribe((data) => {
      this.orgdata = data;
      // console.log('this.dataaccount',this.orgdata.TaxId);
      this.service.getUserDetailsByTaxID(this.orgdata.TaxId).subscribe({
        next: (res: any) => {
          try {
            this.dataaccount = res;
            console.log('this.dataaccount', this.dataaccount);
            // console.log('this.dataaccount',this.dataaccount);
          } catch (error) {
            console.log('not getting account details');

          }
        },
        error: (error: any) => {
          console.error('Error fetching data by tax ID:', error);
        },
      });

    });
    this.Donorlistfordonationpage();
    this.currentDate =
      dtToday.getDate().toString().padStart(2, "0") + "/" + month + "/" + year;
    this.pledgeMinDueDate = `${year}-${month}-${day}`;
    // Get the application main menu
    this.menu = menu;
    this.authSubscription = this._authenticationService.currentUser.subscribe(
      user => {
        this.currentUser = user;
        if (user) {
          console.log('Current user updated:', user);
          // alert(JSON.stringify(user));
        }
      }
    );
    // this.selectedAmount = 0.0;
    const currentUser = this._authenticationService.currentUserValue;
    if (currentUser) {
      this.orgid = currentUser.id;
    } else {
      this.orgid = 0;
    }
    this.currentUserTaxId = this.service.getTaxIdFromLocalStorage();

    // Register the menu to the menu service
    this._coreMenuService.register("main", this.menu);

    // Set the main menu as our current menu
    this._coreMenuService.setCurrentMenu("main");

    // Add languages to the translation service
    this._translateService.addLangs(["en", "fr", "de", "pt"]);

    // This language will be used as a fallback when a translation isn't found in the current language
    this._translateService.setDefaultLang("en");

    // Set the translations for the menu
    // Set the private defaults
    this._unsubscribeAll = new Subject();
    this.newdonorform = this._formBuilder.group(
      {
        title: ["", [Validators.required]], //title
        Firstname: [
          "",
          [Validators.required, Validators.pattern(/^[a-zA-Z]+$/)],
        ],
        Lastname: [
          "",
          [Validators.required, Validators.pattern(/^[a-zA-Z]+$/)],
        ],
        Cellphone: [
          "",
          [
            Validators.pattern(/^\([0-9]{3}\) [0-9]{3}-[0-9]{4}$/),
            Validators.required,
          ],
        ],
        Homephone: [
          "",
          [
            Validators.required,
            Validators.pattern(/^\([0-9]{3}\) [0-9]{3}-[0-9]{4}$/),
          ],
        ],

        Email: [
          "",
          [Validators.required, Validators.pattern(/^\S+@\S+\.\S+$/)],
        ],
        Address: ["", [Validators.required, Validators.pattern(/^[\d\w\s\p{P}\p{S}]*$/u)]],
      },
      { validator: this.phoneNotSameValidator() }
    );

    this.NewDonationAgaiondAmpount = this._formBuilder.group({
      CamapifDropDown: ["", [Validators.required]],
    });

    this.fourthgroup = this._formBuilder.group({
      Street: ["", [Validators.required]], //title
      Apt: ["", [Validators.required, Validators.pattern(/^[a-zA-Z]+$/)]],
      City: ["", [Validators.required, Validators.pattern(/^[a-zA-Z\s]+$/)]],
      State: ["", [Validators.required, Validators.pattern(/^[a-zA-Z\s]+$/)]],
      Zip: ["", [Validators.required]],
      Id: [],
    });
  }
  /**
   * On init
   */
  currentOrg: any;
  ngOnInit(): void {
    this.service.send_DonordataNew$.subscribe(value => {
      console.log('inside nginit value', value);
      this.dtadonor = value[0];
      console.log('sharedservice ', this.dtadonor);
    })
    this.service.send_ReceiptData.subscribe((data) => {
      this.receivedData = data;
      this.finddonordata();
    });

    this.dataaccount = this.service.returnallaccountdata();
    // alert(JSON.stringify(this.dataaccount))
    this.getUserDetails(this.currentUserTaxId);
    this.Donorlistfordonationpage();


    this.fetchLocations();
    this.editCardForm = this._formBuilder.group({
      cardNumber: ["", [Validators.required]],
      cardName: ["", [Validators.required]],
      expiryDate: ["", [Validators.required]],
      cardCvv: ["", [Validators.required]],
    });
    this.newCardForm = this._formBuilder.group({
      cardNumber: ["", [Validators.required]],
      cardName: ["", [Validators.required]],
      expiryDate: ["", [Validators.required]],
      cardCvv: ["", [Validators.required, Validators.pattern('^[0-9]*$')]],
      cardSave: ["", []],
    });

    //this.Donorlistfordonationpage()
    // Init wave effect (Ripple effect)

    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        this.vocuherImageStatusBatch = false;
      });
    //Waves.init();
    this.certificatemanualEntryFormGroup = this._formBuilder.group({
      donorId: ["", Validators.required],
      certificateNumber: ["", Validators.required],
      amount: ["", [Validators.required, Validators.min(0)]],
      checkNumber: ["", [Validators.required, Validators.pattern("^[0-9]*$")]],
    });

    this.filteredDonors = [...this.donorsList];
    this.account = this.service.gettaxid();

    this.orgnasitionId = this.service.getOrganizationIdFromLocalStorage();
    this.DepositToBankAccountID = this.service.settaxid();
    // alert(this.DepositToBankAccountID.AccID);

    // alert(this.orgnasitionId);
    this.currentUserCirtificateAllDataObject =
      this.service.getvalidatecirtificateDatafromLocalstorage();

    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        this.getUserDetails(this.currentUserTaxId);
      });
    this.Donorlistfordonationpage();
    // Subscribe to config changes
    // debugger;
    this._coreConfigService.config
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((config) => {
        // debugger;
        this.coreConfig = config;

        // Set application default language.

        // Change application language? Read the ngxTranslate Fix

        // ? Use app-config.ts file to set default language
        const appLanguage = this.coreConfig.app.appLanguage || "en";
        this._translateService.use(appLanguage);

        // ? OR
        // ? User the current browser lang if available, if undefined use 'en'
        // const browserLang = this._translateService.getBrowserLang();
        // this._translateService.use(browserLang.match(/en|fr|de|pt/) ? browserLang : 'en');

        /**
         * ! Fix : ngxTranslate
         * ----------------------------------------------------------------------------------------------------
         */

        /**
         *
         * Using different language than the default ('en') one i.e French?
         * In this case, you may find the issue where application is not properly translated when your app is initialized.
         *
         * It's due to ngxTranslate module and below is a fix for that.
         * Eventually we will move to the multi language implementation over to the Angular's core language service.
         *
         **/

        // Set the default language to 'en' and then back to 'fr'.

        setTimeout(() => {
          this._translateService.setDefaultLang("en");
          this._translateService.setDefaultLang(appLanguage);
        });

        /**
         * !Fix: ngxTranslate
         * ----------------------------------------------------------------------------------------------------
         */

        // Layout
        //--------

        // Remove default classes first
        this._elementRef.nativeElement.classList.remove(
          "vertical-layout",
          "vertical-menu-modern",
          "horizontal-layout",
          "horizontal-menu"
        );
        // Add class based on config options
        if (this.coreConfig.layout.type === "vertical") {
          this._elementRef.nativeElement.classList.add(
            "vertical-layout",
            "vertical-menu-modern"
          );
        } else if (this.coreConfig.layout.type === "horizontal") {
          this._elementRef.nativeElement.classList.add(
            "horizontal-layout",
            "horizontal-menu"
          );
        }

        // Navbar
        //--------

        // Remove default classes first
        this._elementRef.nativeElement.classList.remove(
          "navbar-floating",
          "navbar-static",
          "navbar-sticky",
          "navbar-hidden"
        );
        // debugger;

        this.coreConfig.layout.navbar.type = "fixed-top";
        // Add class based on config options
        if (this.coreConfig.layout.navbar.type === "navbar-static-top") {
          this._elementRef.nativeElement.classList.add("navbar-static");
        } else if (this.coreConfig.layout.navbar.type === "fixed-top") {
          this._elementRef.nativeElement.classList.add("navbar-sticky");
        } else if (this.coreConfig.layout.navbar.type === "floating-nav") {
          this._elementRef.nativeElement.classList.add("navbar-floating");
        } else {
          this._elementRef.nativeElement.classList.add("navbar-hidden");
        }

        // Footer
        //--------

        // Remove default classes first
        this._elementRef.nativeElement.classList.remove(
          "footer-fixed",
          "footer-static",
          "footer-hidden"
        );

        // Add class based on config options
        if (this.coreConfig.layout.footer.type === "footer-sticky") {
          this._elementRef.nativeElement.classList.add("footer-fixed");
        } else if (this.coreConfig.layout.footer.type === "footer-static") {
          this._elementRef.nativeElement.classList.add("footer-static");
        } else {
          this._elementRef.nativeElement.classList.add("footer-hidden");
        }

        // Blank layout
        if (
          this.coreConfig.layout.menu.hidden &&
          this.coreConfig.layout.navbar.hidden &&
          this.coreConfig.layout.footer.hidden
        ) {
          this._elementRef.nativeElement.classList.add("blank-page");
          // ! Fix: Transition issue while coming from blank page
          this._renderer.setAttribute(
            this._elementRef.nativeElement.getElementsByClassName(
              "app-content"
            )[0],
            "style",
            "transition:none"
          );
        } else {
          this._elementRef.nativeElement.classList.remove("blank-page");
          // ! Fix: Transition issue while coming from blank page
          setTimeout(() => {
            this._renderer.setAttribute(
              this._elementRef.nativeElement.getElementsByClassName(
                "app-content"
              )[0],
              "style",
              "transition:300ms ease all"
            );
          }, 0);
          // If navbar hidden
          if (this.coreConfig.layout.navbar.hidden) {
            this._elementRef.nativeElement.classList.add("navbar-hidden");
          }
          // Menu (Vertical menu hidden)
          if (this.coreConfig.layout.menu.hidden) {
            this._renderer.setAttribute(
              this._elementRef.nativeElement,
              "data-col",
              "1-column"
            );
          } else {
            this._renderer.removeAttribute(
              this._elementRef.nativeElement,
              "data-col"
            );
          }
          // Footer
          if (this.coreConfig.layout.footer.hidden) {
            this._elementRef.nativeElement.classList.add("footer-hidden");
          }
        }

        // Skin Class (Adding to body as it requires highest priority)
        if (
          this.coreConfig.layout.skin !== "" &&
          this.coreConfig.layout.skin !== undefined
        ) {
          this.document.body.classList.remove(
            "default-layout",
            "bordered-layout",
            "dark-layout",
            "semi-dark-layout"
          );
          this.document.body.classList.add(
            this.coreConfig.layout.skin + "-layout"
          );
        }
      });

    // Set the application page title
    // debugger;
    //this.coreConfig.app.
    this._title.setTitle("Linx OJC- Web Admin ");
    // this.service.donor_Donation.subscribe((dta) => {
    //   this.Donorlistfordonationpage(dta);
    // });
    this.service.donor_Donation.subscribe((dta) => {
      this.Donorlistfordonationpage(dta);
      this.getChart.forEach((donor, indx) => {
        if (dta.DonorId == donor.DonorId) {
          $(".donorTab" + indx + " h2").click();
          //this.SelectDonorlist(donor);

          setTimeout(() => {
            $(".donorListBtn").focus();
          }, 500);
          setTimeout(() => {
            $(".donorListBtn").click();
          }, 1000);
        }
      });

    });
    this.service.send_ReceiptData.subscribe((dta) => {
      console.log("Data received===", dta);
      if (dta.modalType == "sendReceipt") {
        this.CampaignName = dta.campaignName;
        this.donorname = dta.donorName;
        this.SelectedItem = dta.donorId;
        this.Campaigndropdown = dta.campaignId;
        this.orgid = dta.organizationId;
        this.selectedAmount = dta.amount;
        $("#adddonate").modal("show");
        this.CloseAllDenationScreen();
        this.stepper.next();
        this.stepper.next();
        this.receiptClicked();
      } else if (dta.modalType == "donorRepeate") {
        this.CampaignName = dta.campaignName;
        this.paidDonationId = dta.donationId;
        this.donorname = dta.donorName;
        this.SelectedItem = dta.donorId;
        this.Campaigndropdown = dta.campaignId;
        this.orgid = dta.organizationId;
        this.selectedAmount = dta.amount;
        this.selectedRepeatDate = dta.startDate;
        $("#adddonate").modal("show");
        this.CloseAllDenationScreen();
        this.stepper.next();
        this.stepper.next();
        this.checkfun();
      }
    });
    this.getbactdatafromlocalstorage();
    // this.onchecksubmit();
  }
  stepper;
  donorid;
  sortfilters = false;
  paidDonationId = null;
  changeDueAmount: string = "0";
  processing: boolean = false;
  AferPyment: boolean = false;
  paymentSuccessful: boolean = false;
  paymentFailure: boolean = false;
  receiptScreen: boolean = false;
  GetDate = [];
  // stepperboolen3 = false;
  // donorId=[];
  newDonationCash() {
    if (!this.selectedAmount) {
      this.showToast("Please enter valid amount", "error");
      return;
    }

    if (this.selectedAmount > this.campaigngoal) {
      // Show error message
      this.showToast(
        `Amount exceeds campaign goal ${this.campaigngoal.toLocaleString()}`,
        "error"
      );
      // Prevent further action
      return;
    }
    this.cashdiv = false;
    this.checkdiv = false;
    let obj = {
      PledgeId: this.selectedPledge,
      DonationId: 0,
      DonorId: this.SelectedItem,
      CampaignId: this.Campaigndropdown,
      OrganizationId: this.orgid,
      Amount: this.selectedAmount,
      ChangeDue: this.changeDueAmount,
      PaymentMethod: "Cash",
      DonationStatus: "Approved",
    };
    this.loading = true;
    this.processing = true;
    this.stepperboolen3 = true;

    this.service.AddDonation(obj).subscribe(
      (res) => {
        if (res.success) {
          this.SelectedItem = 0;
          this.paidDonationId = res.donationId;
          // this.donorId.push(this.SelectedItem)
          this.processing = false;
          this.AferPyment = true;
          this.paymentSuccessful = true;
          this.paymentFailure = false;
          this.updateDonationList();
          this.updateDashboard();

          this.showToast(res.message, "success");
        } else {
          this.loading = false;
          this.processing = false;
          this.AferPyment = true;
          this.paymentSuccessful = false;
          this.paymentFailure = true;
          this.showToast("Error!", "error");
        }
      },
      (error) => {
        this.loading = false;
        this.processing = false;
        this.AferPyment = true;
        this.paymentSuccessful = false;
        this.paymentFailure = true;
        this.showToast("Error!", "error");
      }
    );
    // console.log(' this.donorId', this.donorId)
  }

  
  newDonationCheck(stepper) {
    if (!this.checkURL) {
      this.showToast("Please upload check image", "error");
    } else if (!this.checkNumber) {
      this.showToast("Please enter check number", "error");
    } else if (!this.repeatedPayDiv) {
      this.cashdiv = false;
      this.checkdiv = false;
      this.loading = true;
      this.processing = true;
      this.stepperboolen3 = true;

      let obj = {
        PledgeId: this.selectedPledge,
        DonorId: this.SelectedItem,
        CampaignId: this.Campaigndropdown,
        OrganizationId: this.orgid,
        Amount: this.selectedAmount,
        PaymentMethod: "Check",
        CheckNumber: this.checkNumber,
        CheckImageLocation: this.checkURL,
        RepeatId: this.paidDonationId,
      };
      this.service.AddCheckDonation(obj).subscribe(
        (res) => {
          if (res.success) {
            this.paidDonationId = res.donationId;
            this.SelectedItem = 0;
            // this.donorId.push(this.SelectedItem)
            this.processing = false;
            this.AferPyment = true;
            this.paymentSuccessful = true;
            this.paymentFailure = false;
            this.checkURL = null;
            this.checkNumber = null;
            this.updateDonationList();
            this.updateDashboard();
            this.showToast(res.message, "success");
          } else {
            this.loading = false;
            this.processing = false;
            this.AferPyment = true;
            this.paymentSuccessful = false;
            this.paymentFailure = true;
            this.showToast("Error!", "error");
          }
        },
        (error) => {
          this.loading = false;
          this.processing = false;
          this.AferPyment = true;
          this.paymentSuccessful = false;
          this.paymentFailure = true;
          this.showToast("Error!", "error");
          console.log(error);
          // /*******temp******** */
          // setTimeout(() => {
          //   this.processing = false;
          //   this.AferPyment = true;
          //   this.paymentSuccessful = true;
          //   this.paymentFailure = false;
          // }, 5000);
          // /*************** */
        }
      );
    } else if (this.repeatedPayDiv) {
      this.cardDonationForRecurring("", "", stepper, "", "", "", "check");
    }
  }

  receiptChart: Chart = null;
  smsActive: boolean = false;
  messageActive: boolean = false;
  addNewEmail: boolean = false;
  addNewSMS: boolean = false;
  receiptClicked() {
    this.newCardNumber = null;
    this.cardSelected = null;
    this.AferPyment = false;
    this.paymentSuccessful = false;
    this.paymentFailure = false;
    this.receiptScreen = true;

    let d1 = (30 / 70) * 100;
    let d2 = 100 - d1;
    var tempObj: any = {
      type: "pie", //this denotes tha type of chart
      data: {
        // values on X-Axis
        datasets: [
          {
            data: [d1, d2],
            backgroundColor: ["red", "white"],
            borderColor: "#DCDFE4",
            borderWidth: 0.5,
          },
        ],
      },
      options: {
        aspectRatio: 2.1,
        maintainAspectRatio: false,
        events: [],
      },
    };
    setTimeout(() => {
      this.receiptChart = new Chart("MyReceiptChart", tempObj);
    }, 500);
  }

  messageClicked() {
    this.smsActive = false;
    this.messageActive = !this.messageActive;
    console.log("message clicked", this.messageActive);
    //$('#mailDetailBox').toggle();
    //$('#smsDetailBox').hide();
  }
  addNewEmailChange(evt) {
    this.addNewEmail = evt.target.checked;
  }
  addNewSMSChange(evt) {
    this.addNewSMS = evt.target.checked;
  }
  smsClicked() {
    this.messageActive = false;
    this.smsActive = !this.smsActive;
    console.log("sms clicked", this.smsActive);
    //$('#mailDetailBox').hide();
    //$('#smsDetailBox').toggle();
  }
  emailList = ["jack@gmail.com", "jake@company.com"];
  // smsList = ['5169089654', '5169089654'];
  sendEmailSms(stepper) {
    if (this.messageActive) {
      let mailArr = [];
      this.document.getElementsByClassName("mailSent")[0].checked &&
        mailArr.push(this.emailList[0]);
      this.document.getElementsByClassName("mailSent")[1].checked &&
        mailArr.push(this.emailList[1]);
      this.document.getElementsByClassName("mailSent")[2].checked &&
        mailArr.push($(".customMail").val());
      let obj = {
        donationId: this.paidDonationId || this.receivedData.donationId,
        emails: mailArr,
        contacts: null,
      };
      this.service.sendEmail(obj).subscribe(
        (dta) => {
          this.showToast("Email sent", "success");
          this.closeDonationPop(stepper);
        },
        (error) => {
          this.showToast("Server error!", "error");
          this.closeDonationPop(stepper);
        }
      );
    } else {
      let smsArr = [];
      this.document.getElementsByClassName("smsSent")[0].checked &&
        smsArr.push(this.smsList[0]);
      this.document.getElementsByClassName("smsSent")[1].checked &&
        smsArr.push(this.smsList[1]);
      this.document.getElementsByClassName("smsSent")[2].checked &&
        smsArr.push($(".customSMS").val());
      let obj = {
        donationId: this.paidDonationId || this.receivedData.donationId,
        emails: null,
        contacts: smsArr,
      };
      this.service.sendSMS(obj).subscribe(
        (dta) => {
          this.showToast(dta.message, "success");
          this.closeDonationPop(stepper);
        },
        (error) => {
          this.showToast(error, "error");
          this.closeDonationPop(stepper);
        }
      );
    }
  }

  printReciept(stepper) {
    // alert(stepper)
    let obj = {
      donationId: this.paidDonationId || this.receivedData.donationId,
      emails: null,
      contacts: null,
    };
    this.service.printReciept(obj).subscribe(
      (dta) => {
        if (dta.success) {
          window.open(dta.filePath, "_blank");
          this.showToast("Reciept downloaded successfully.", "success");
          this.closeDonationPop(stepper);
        }
      },
      (error) => {
        this.showToast("Server error!", "error");
        this.closeDonationPop(stepper);
      }
    );
  }

  TransformValue(value) {
    if (isNaN(value) || value === null) {
      return "";
    }
    return "$" + value.toFixed(2);
  }

  phoneNotSameValidator(): ValidatorFn {
    return (formGroup: AbstractControl): { [key: string]: boolean } | null => {
      const cellPhone = formGroup.get("Cellphone")?.value;
      const homePhone = formGroup.get("Homephone")?.value;

      if (cellPhone && homePhone && cellPhone === homePhone) {
        return { phonesMatch: true };
      }
      return null;
    };
  }
  home: any;
  cell: any;
  newdonorformSubmit() {
    this.submittedform = true;
    if (this.newdonorform.invalid) {
      return;
    }
    let obj = {
      "OrganizationId": this.orgid,
      "Title": this.newdonorform.controls.title.value.$ngOptionLabel,
      "DonorName": this.newdonorform.controls.Firstname.value,
      "DonorNameHe": this.newdonorform.controls.Lastname.value,
      "PrimaryContact": this.newdonorform.controls.Cellphone.value,
      "PrimaryEmail": this.newdonorform.controls.Email.value,
      "Address": this.newdonorform.controls.Address.value,
      "HomePhone": this.newdonorform.controls.Homephone.value,
      // "WorkPhone": this.newdonorform.controls.Cellphone.value
    }
    console.log("orgid", this.orgid,);

    this.loading = true;
    this.home = this.newdonorform.controls.Homephone.value;
    this.cell = this.newdonorform.controls.Cellphone.value;

    if (this.home == this.cell) {
      this.loading = false;
      this._toastrService.error(
        "Cellphone and HomePhone should not be same",
        "",
        {
          toastClass: "toast ngx-toastr",
          closeButton: true,
          timeOut: 5000,
          disableTimeOut: false,
        }
      );
    } else {
      this.service.adddonorfromdonation(obj).subscribe(
        (res) => {
          //debugger;
          this.loading = false;
          if (res.success) {
            this.Donorlistfordonationpage();
            this.closeDonationPop(this.stepper);
            //debugger;
            this.loading = false;
            this.newdonorform.reset();
            this.submittedform = false;
            this.donoradd = false;
            this.Donorlistfordonationpage();

            $("#CloseButtonAdd").click();
            this._toastrService.success(res.message, "", {
              toastClass: "toast ngx-toastr",
              closeButton: true,
              timeOut: 5000,
              disableTimeOut: false,
            });
            this.service.update_DonorList.next();
          } else {
            this.loading = false;
            this._toastrService.error("Error!", "", {
              toastClass: "toast ngx-toastr",
              closeButton: true,
              timeOut: 5000,
              disableTimeOut: false,
            });
          }
        },

        (error) => {
          console.log(error);
        }
      );
    }
  }
  CampaignListAPi() {
    let obj = {
      OrganizationId: this.orgid,
      Paging: {
        PageSize: 1000,
        PageIndex: 1,
      },
    };

    //this.service.GetList(obj).subscribe((res) => {
    this.service.getTopCampaignList(obj).subscribe(
      (res) => {
        this.Campaign = [];
        let tempArr = res.d.Campaigns;
        tempArr.forEach((data) => {
          data.DaysLeft >= 0 && this.Campaign.push(data);
        });
      },
      (error) => {
        console.log(error);
      }
    );
  }
  getChart = [];
  PrimaryContact = [];
  emailexist = [];
  donodata: any;
  // Donorlistfordonationpage(moveTo = null) {
  //   if(!this.orgid)
  //     this.orgid = JSON.parse(localStorage.getItem('currentUser')).id;
  //   let obj = {
  //     OrganizationId: this.orgid,
  //   };

  //   this.service.GetDonorList(obj).subscribe(
  //     (res) => {
  //       this.donorslist = res.d.Donors;
  //       // console.log('this.donorslist333', this.donorslist);

  //       this.getChart.length = 0;
  //       this.donorslist.forEach((dta) => {
  //         // alert('dfdsf')
  //         dta.DOB && (dta.DOB = dta.DOB.split('T')[0].split('-').reverse().join('/'));
  //         this.getChart.push(dta);
  //         this.PrimaryContact.push(dta.PrimaryContact);
  //         this.emailexist.push(dta.PrimaryEmail);

  //         // console.log('this.emailexist',  this.PrimaryContact);
  //       });

  //       if (moveTo) {
  //         //this is doner come directly from donor page 'Donor detail popup'
  //         this.getChart.forEach((donor, indx) => {
  //           console.log(moveTo.DonorId + "==" + donor.DonorId);
  //           if ("" + moveTo.DonorId == "" + donor.DonorId) {
  //             $(".donorTab" + indx + " h2").click();
  //             $(".donorListBtn").focus();
  //             $(".donorListBtn").click();
  //           }
  //         });
  //       }
  //     },
  //     (error) => {
  //       console.log(error);
  //     }
  //   );
  // }
  newDonorlist: any = [];
  Donorlistfordonationpage(moveTo = null) {
    console.log('First Time');
    const currentUser = localStorage.getItem('currentUser');
    if (currentUser) {
      try {
        const userObj = JSON.parse(currentUser);
        if (userObj && userObj.id) {
          this.orgid = userObj.id;
        } else {
          console.warn('Invalid user object in localStorage');
          this.orgid = null;
        }
      } catch (e) {
        console.error('Error parsing localStorage item', e);
        this.orgid = null;
      }
    } else {
      console.warn('No currentUser found in localStorage');
      this.orgid = null;
    }

    let obj = {
      OrganizationId: this.orgid,
    };

    // this.service.GetDonorList(obj).subscribe(
    //   (res) => {
    //     if (res.success) {
    //       this.donorslist = res.d.Donors;
    //       this.getChart.length = 0;
    //       this.donorslist.forEach((dta) => {
    //         console.log("dataData:",dta);
    //         dta.DOB && (dta.DOB = dta.DOB.split('T')[0].split('-').reverse().join('/'));
    //         this.getChart.push(dta);
    //         this.PrimaryContact.push(dta.PrimaryContact);
    //         this.emailexist.push(dta.PrimaryEmail);
    //       });
    //       this.newDonorlist[0] = this.donorslist;
    //       console.log('inside donor list ',this.newDonorlist );
    //       this.service.updateDonordataNew(this.newDonorlist)
    //       if (moveTo) {
    //         this.getChart.forEach((donor, indx) => {
    //           console.log('sdd', moveTo.DonorId + "==" + donor.DonorId);
    //           if ("" + moveTo.DonorId == "" + donor.DonorId) {
    //             $(".donorTab" + indx + " h2").click();
    //             $(".donorListBtn").focus();
    //             $(".donorListBtn").click();
    //           }
    //         });
    //       }
    //     }
    //   },
    //   (error) => {
    //     console.log(error);
    //   }
    // );

    this.service.GetDonorList(obj).subscribe(
      (res) => {
        if (res.success) {
          this.donorslist = res.d.Donors;
          this.getChart.length = 0;
          this.donorslist.forEach((dta) => {
            console.log("dataData:", dta);
            if (dta.DOB) {
              const [day, month, year] = dta.DOB.split('/');

              if (day && month && year) {
                
                dta.DOB = `${year}-${month}-${day}`;
              }
            }
           
            this.getChart.push(dta);
            this.PrimaryContact.push(dta.PrimaryContact);
            this.emailexist.push(dta.PrimaryEmail);
          });
          this.newDonorlist[0] = this.donorslist;
          console.log('inside donor list ', this.newDonorlist);
          this.service.updateDonordataNew(this.newDonorlist)
          if (moveTo) {
            this.getChart.forEach((donor, indx) => {
              console.log('sdd', moveTo.DonorId + "==" + donor.DonorId);
              if ("" + moveTo.DonorId == "" + donor.DonorId) {
                $(".donorTab" + indx + " h2").click();
                $(".donorListBtn").focus();
                $(".donorListBtn").click();
              }
            });
          }
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }

  smsList: any = [];
  finddonordata() {
    this.donodata = this.donorslist.find(
      (donor) => donor.DonorId === this.receivedData.donorId
    );
    // console.log("this.donodata ", this.donodata);
    this.smsList.push(this.donodata.HomePhone);
    this.smsList.push(this.donodata.WorkPhone);
  }
  rows2 = [
    {
      DonorId: "d1",
      DonorName: "Mr1 Elmer processed",
      DonorAddress: "Brooklyn NY 11345",
      PrimaryContact: "0987654321",
      WorkPhone: "1234567890",
      PrimaryEmail: "rocky1@gmail.com",
    },
    {
      DonorId: "d2",
      DonorName: "Mr2 Elmer processed",
      DonorAddress: "Brooklyn NY 11345",
      PrimaryContact: "0987654321",
      WorkPhone: "1234567890",
      PrimaryEmail: "rocky2@gmail.com",
    },
    {
      DonorId: "d3",
      DonorName: "Mr3 Elmer processed",
      DonorAddress: "Brooklyn NY 11345",
      PrimaryContact: "0987654321",
      WorkPhone: "1234567890",
      PrimaryEmail: "rocky3@gmail.com",
    },
    {
      DonorId: "d4",
      DonorName: "Mr4 Elmer processed",
      DonorAddress: "Brooklyn NY 11345",
      PrimaryContact: "0987654321",
      WorkPhone: "1234567890",
      PrimaryEmail: "rocky4@gmail.com",
    },
    {
      DonorId: "d5",
      DonorName: "Mr5 Elmer processed",
      DonorAddress: "Brooklyn NY 11345",
      PrimaryContact: "0987654321",
      WorkPhone: "1234567890",
      PrimaryEmail: "rocky5@gmail.com",
    },
    {
      DonorId: "d6",
      DonorName: "Mr6 Elmer processed",
      DonorAddress: "Brooklyn NY 11345",
      PrimaryContact: "0987654321",
      WorkPhone: "1234567890",
      PrimaryEmail: "rocky6@gmail.com",
    },
  ];

  // searchTerm = "";
  // filterUpdate() {
  //   //debugger;
  //   if (this.searchTerm.trim() === "") {
  //     // If search term is empty, reset to the original getChart data
  //     this.getChart = [...this.donorslist];
  //   } else {
  //     // Filter the chart data based on the search term
  //     const filteredChart = this.donorslist.filter((chartData) =>
  //       chartData.DonorName.toLowerCase().includes(
  //         this.searchTerm.toLowerCase()
  //       )
  //     );

  //     // Update the chart data to display only the filtered results
  //     this.getChart = filteredChart;
  //   }
  // }

  // Initialize donorslist as an empty array to avoid undefined errors
  // donorslist: any[] = [];
  searchTerm = "";
  // getChart: any[] = [];

  filterUpdate() {

    if (!Array.isArray(this.donorslist)) {
      console.info("Info: donorslist is empty");
      this.getChart = [];
      return;
    }


    if (this.searchTerm.trim() === "") {
      this.getChart = [...this.donorslist];
    } else {

      const filteredChart = this.donorslist.filter((chartData) =>
        chartData.DonorName.toLowerCase().includes(this.searchTerm.toLowerCase())
      );


      if (filteredChart.length === 0) {
        console.log("No matching donors found.");
        this.getChart = [];
      } else {

        this.getChart = filteredChart;
      }
    }
  }



  formatPhoneNumber(phoneNumberString: string): string {
    var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
    var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return "(" + match[1] + ") " + match[2] + "-" + match[3];
    }
    return phoneNumberString;
  }
  onPhoneNumberChange(event: any, controlName: string): void {
    const inputValue = event.target.value;
    let formattedValue = "";

    if (controlName === "Cellphone") {
      formattedValue = this.formatPhoneNumber(inputValue);
    } else if (controlName === "Homephone") {
      formattedValue = this.formatPhoneNumber(inputValue);
    } else if (controlName === "Workphone") {
      formattedValue = this.formatPhoneNumber(inputValue);
    }

    this.newdonorform
      .get(controlName)
      .setValue(formattedValue, { emitEvent: false });
  }

  campaigngoal: any;
  getCampaignName(value) {
    this.Campaigndropdown = value;
    const selectedCamp = this.Campaign.find(
      (donor) => donor.CampaignId === this.Campaigndropdown
    );
    if (selectedCamp) {
      this.CampaignName = selectedCamp.Name;
      this.selectedCampaign = selectedCamp;
      this.campaigngoal = selectedCamp.Goal;
    } else {
      console.log("error");
    }
  }
  SelectedItem: any;
  donorname = null;

  SelectDonorlist(value) {
    if (this.SelectedItem != value.DonorId) {
      this.SelectedItem = value.DonorId;
      this.donorid = value.DonorId;
      this.donorname = value.DonorName;
    } else {
      this.SelectedItem = value.DonorId;
      this.donorid = value.DonorId;
      this.donorname = value.DonorName;
    }
  }

  getCamapignDropdownName: any;

  // slectDropdownNadGetValue(fdf) {
  // //debugger
  //   // this.getCamapignDropdownName = event;
  //   // console.log(this.getCamapignDropdownName);
  // }

  goForward(stepper: MatStepper) {
    $("#accordionExample")
      .find("#id" + this.SelectedItem)
      .addClass("collapse")
      .removeClass("show");
    $("#accordionExample")
      .find(".cardselected")
      .addClass("card")
      .removeClass("cardselected");
    if (this.SelectedItem == "" || this.SelectedItem == undefined) {
      this.showToast("Please select a donor", "error");
      return;
    }
    {
      this.CampaignListAPi();
      this.stepperboolen = true;
      this.donationamount = true;
      this.schedulePledge = false;
      stepper.next();
    }
  }

  selectedAmount: string;
  selectedPledge: string = null;
  selectedTotalPledge: string;
  checkNumber: string;

  setAmount(amount: string): void {
    this.selectedAmount = amount;
    this.onAmountChange();
  }
  // stepperboolen2 = false;
  // stepperboolen4 = true;
  // stepperboolen5 = true;
  // NewDonation:FormGroup
  goForwardOn(stepper: MatStepper) {
     this.stepperboolen = true;
    console.log(
      this.Campaigndropdown,
      this.selectedAmount,
      parseInt(this.selectedAmount)
    );
    if (this.Campaigndropdown == null || this.Campaigndropdown == undefined) {
      this.showToast("Please select campaign Name", "error");
      return;
    } else if (this.scanCertificateFlag) {
      this.saveScanCertificate(stepper);
      return;
    } else if (!this.selectedAmount || parseInt(this.selectedAmount) <= 0) {
      this.showToast("Please enter at least $1", "error");
      return;
    } else if (Number(this.selectedAmount) > Number(this.campaigngoal)) {
      this.showToast(
        `Amount exceeds campaign goal ${this.campaigngoal.toLocaleString()}`,
        "error"
      );
      return;

      
    }
    {
      this.CloseAllDenationScreen();
      this.selectedPledge = null;
      this.selectedTotalPledge = null;
      this.stepperboolen2 = true;
      this.donationtypes = true;
      this.openOtherMethod();
      stepper.next();
    }
    //this.receiptClicked();
  }

  donateFunc() {
    this.paymentMethod = true;
    this.donationtypes = false;
    this.AferPyment = false;
  }

  Inintial(stepper: MatStepper) {
    this.stepper = stepper;
  }

  resetfunc(stepper: MatStepper) {
    this.SelectedItem = null;
    this.donorid = null;
    this.donorname = null;
    this.newdonorform.reset();
    this.submittedform = false;
    this.SelectedItem = 0;
    this.donorsearch = true;
    this.scancertificate = false;
    this.Campaigndropdown = null;
    this.selectedAmount = null;
    this.checkNumber = null;
    this.paidDonationId = null;
    this.donoradd = false;
    this.stepperboolen = false;
    this.stepperboolen2 = false;
    this.stepperboolen3 = false;
    this.manualcertificatentry = false;
    this.secondmanual = false;
    this.paymentmethodform = false;
    this.paymentaccount = false;
    this.redirectModal = false;
    this.paymentcheck = false;
    this.changeaddress = false;
    this.walets = false;
    this.loadingprocess = false;
    this.ojctype = true;
    this.checkconfirm = false;
    this.CloseAllDenationScreen();
    stepper.previous();
    stepper.previous();
    stepper.previous();
  }
  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }



  pattern = /^[\w]*$/;
  alphaNumericOnly(event): boolean {
    console.log(event.target.value);
    const charCode = event.target.value;
    if (this.pattern.test(charCode)) {
      return true;
    } else {
      this.checkNumber = this.checkNumber.substring(
        0,
        this.checkNumber.length - 1
      );
      return false;
    }
  }
  numericOnly(event: KeyboardEvent): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }


  isButtonDisabled: boolean = false;
  onAmountChange(): void {
    if (this.campaigngoal === null) {
      // No campaign selected
      this.showToast("Please select a campaign first.", "error");
      this.isButtonDisabled = true;
      return;
    }

    if (Number(this.selectedAmount) > Number(this.campaigngoal)) {
      // this.showToast(
      //   `Amount exceeds campaign goal ${this.campaigngoal.toLocaleString()}`,
      //   "error"
      // );
      // this.isButtonDisabled = true;
    } else {
      this.isButtonDisabled = false;
    }
  }

  secondFormGroup = this._formBuilder.group({
    secondCtrl: ["", Validators.required],
  });
  thirdFormGroup = this._formBuilder.group({
    thirdCtrl: ["", Validators.required],
  });
  // fourthFormGroup = this._formBuilder.group({
  //   fourthCtrl: ['', Validators.required],
  // });
  isLinear = false;
  noresult: any = false;
  donorsearch: any = true;
  donoradd: any = false;
  secondmanual: any = false;
  scancertificate: any = false;
  donationamount: boolean = true;
  donationtypes: any = false;
  repeatediv: boolean = false;
  repeatedPayDiv: boolean = false;
  listtick: boolean = true;
  comtick: boolean = false;
  schedulePledge: boolean = false;
  paymentMethod: any = true;
  cashdiv: boolean = false;
  cardDetailDiv: boolean = false;
  cardEditDiv: boolean = false;
  crdEdit: boolean = false;
  cardAddDiv: boolean = false;
  checkdiv: boolean = false;
  cardSelected: number = null;
  newCardNumber: string = null;
  scancertificatediv: any = false;
  followpledgediv: boolean = false;
  showScannerManual: boolean = false;
  manualcertificatentry: any = false;
  paymentmethodform: any = false;
  paymentaccount: any = false;
  paymentcheck: any = false;
  changeaddress: any = false;
  walets: any = false;
  successreports: any = false;
  totalreports: any = false;
  methodpayment: any = true;
  modalstart: any = true;
  ojctype: any = true;
  followpledgefun() {
    this.followpledgediv = true;
    this.paymentMethod = false;
    this.paymentType = "pledge";
  }

  scancertfun(stepper) {
    this.scancertificate = true;
    this.showScanner = true;
    this.showScannerManual = false;
    this.showScannerDonor = false;
    this.donorsearch = false;
    this.resetCamScan();
    stepper.previous();
    stepper.previous();
  }
  cashfun(selectobject) {
    this.paymentMethod = false;
    this.cashdiv = true;
    this.paymentType = "cash";
    this.calculateChangeDue(); 
  }
  cardfun() {
    this.cardSelected = null;
    this.cardDetailDiv = true;
    this.paymentMethod = false;
    const obj = {
      DonorId: this.donorid, //132
      OrganizationId: this.orgid, //13
    };
    this.service.getDonorCardList(obj).subscribe((dta: any) => {
      let crdList = dta.d;
      let objArr: any = [];
      //{ cardNumber: '2112111111114321', cardName: 'abc1', expDate: '21/2/2030' , status: false }
      crdList.forEach((crd) => {
        let obj: any = {};
        obj.cardNumber = crd.LastFour;
        obj.cardName = crd.Name;
        const exp = crd.ExpDate.split("");
        obj.expDate = exp[0] + exp[1] + "/20" + exp[2] + exp[3];
        obj.status = crd.Status == "Approved" ? true : false;
        obj.token = crd.Token;
        obj.saveCardId = crd.SaveCardId;
        obj.cardType = crd.CardType;
        obj.cardCvv = "";
        objArr.push(obj);
      });
      this.crdDetail = objArr.concat();
      this.crdDetail.forEach((crd, i) => {
        let elem = <HTMLInputElement>(
          document.getElementsByClassName("creditCrd-list-col")[i]
        );
        elem && (elem.style.border = "0px solid");
      });
    });
    this.paymentType = "card";
  }
  checkfun() {
    this.checkdiv = true;
    this.paymentMethod = false;
    this.cardDetailDiv = false;
    this.cashdiv = false;
    this.paymentType = "check";
  }
  schedulePledgeFun() {
    if (this.Campaigndropdown == null || this.Campaigndropdown == undefined) {
      this.showToast("Please select campaign Name", "error");
      return;
    } else {
      this.CloseAllDenationScreen();
      this.schedulePledge = true;
    }
  }
  donfun() {
    this.listtick = true;
    this.comtick = false;
    (<HTMLInputElement>(
      document.getElementsByClassName("tab-paneWeek")[0]
    )).classList.add("active");
    (<HTMLInputElement>(
      document.getElementsByClassName("tab-paneMonth")[0]
    )).classList.remove("active");
  }
  donfun2() {
    this.comtick = true;
    this.listtick = false;
    (<HTMLInputElement>(
      document.getElementsByClassName("tab-paneWeek")[0]
    )).classList.remove("active");
    (<HTMLInputElement>(
      document.getElementsByClassName("tab-paneMonth")[0]
    )).classList.add("active");
  }
  /*repeatefun() {
    this.repeatediv = true;
    this.donationamount = false;
    this.donationtypes = false;
    this.schedulePledge = false;
  }
  donatenowfun() {
    this.donationamount = false;
    this.donationtypes = true;
    this.repeatediv = false;
  }*/
  adddonorfun() {
    this.donorsearch = false;
    this.donoradd = true;
    this.scancertificate = false;
    this.newdonorform.reset();
    this.newdonorform.controls["title"].setValue("");
  }
  // custom funtion by developer
  manualcertificate() {
    this.manualcertificatentry = true;
    this.secondmanual = false;
  }
  submitformpayment() {
    this.paymentmethodform = true;
    this.secondmanual = false;
    this.manualcertificatentry = false;
  }
  gotopayment() {
    this.paymentmethodform = false;
    this.paymentaccount = true;
  }
  gotopaymentcheck() {
    this.paymentmethodform = false;
    this.paymentcheck = true;
  }
  changeaddresscheck(data) {
    this.fourthgroup.patchValue({
      Street: data.Street || "",
      Apt: data.Apt || "",
      City: data.City || "",
      State: data.State || "",
      Zip: data.Zip || "",
      Id: data.Id || "",
    });
    console.log("fomrgroup", this.fourthgroup);

    // alert(JSON.stringify(data))
    this.paymentcheck = false;
    this.changeaddress = true;
  }
  walletsfun() {
    this.changeaddress = false;
    this.walets = true;
    this.paymentaccount = false;
    this.paymentcheck = false;
  }
  updatelocation() {
    // alert("hii");
    // alert(this.userid.toString());
    this.submittedform = true;
    // if (this.fourthgroup.invalid) {
    //   return;
    // }
    console.log(this.fourthgroup);

    const newLocationformData = {
      userId: "",
      searchText: "",
      searchField: "",
      paging: {
        pageSize: 0,
        pageIndex: 0,
      },
      Id: this.fourthgroup.get("Id").value,
      OrganizationId: this.orgid,
      DonorId: 0,
      RefId: this.orgid,
      RefType: "Correspondance",
      AddressType: "Correspondance",
      Address1: "",
      Address2: "",
      street: this.fourthgroup.get("Street").value.toString(),
      apt: this.fourthgroup.get("Apt").value.toString(),
      city: this.fourthgroup.get("City").value.toString(),
      state: this.fourthgroup.get("State").value.toString(),
      zip: parseInt(this.fourthgroup.get("Zip").value),
      Latitude: "",
      Longitude: "",
      PhoneNumbers: [
        {
          Id: 0,
          RefId: 0,
          RefType: "",
          PhoneType: "",
          PhoneNumber: "",
        },
      ],
    };
    // alert(JSON.stringify(newLocationformData));
    // var formType = "Add";
    if (this.fourthgroup.get("Id").value) {
      this.service.updatelocationbatch(newLocationformData).subscribe({
        next: (res: any) => {
          // alert(JSON.stringify(res));
          // alert("2");
          if (res) {
            this.fetchLocations();
            this.changeaddress = false;
            this.walets = false;
            this.paymentaccount = false;
            this.paymentcheck = true;
          }
        },
        error: (error: any) => {
          console.error(
            "Error updating API key:",
            error.message || error.error || "Unknown error"
          );
        },
        complete: () => {
          console.log("Update connection request completed");
        },
      });
    }
  }
  submitsuccess() {
    this.walets = false;
    this.successreports = true;
  }

  opencertificate() {
    this.ojctype = false;
    this.secondmanual = true;
  }
  backbttonss() {
    this.ojctype = true;
    if (this.secondmanual) {
      this.ojctype = true;
      this.secondmanual = false;
      this.totalcertificate = false;
    } else if (this.paymentmethodform) {
      this.secondmanual = true;
      this.ojctype = false;
      this.paymentmethodform = false;
      this.totalcertificate = false;
    } else if (this.paymentaccount) {
      this.paymentmethodform = true;
      this.ojctype = false;
      this.paymentaccount = false;
      this.totalcertificate = false;
    } else if (this.paymentcheck) {
      this.paymentmethodform = true;
      this.ojctype = false;
      this.paymentcheck = false;
      this.totalcertificate = false;
    } else if (this.manualcertificatentry) {
      this.secondmanual = true;
      this.ojctype = false;
      this.manualcertificatentry = false;
      this.totalcertificate = false;
    } else if (this.walets) {
      this.paymentmethodform = true;
      this.ojctype = false;
      this.walets = false;
      this.totalcertificate = false;
    } else if (this.successreports) {
      this.walets = true;
      this.ojctype = false;
      this.successreports = false;
      this.totalcertificate = false;
    } else if (this.changeaddress) {
      this.paymentcheck = true;
      this.ojctype = false;
      this.changeaddress = false;
      this.totalcertificate = false;
    } else if (this.totalreports) {
      this.successreports = true;
      this.ojctype = false;
      this.totalreports = false;
      this.totalcertificate = false;
    }
    else if (this.redirectModal) {
      this.ojctype = false;
      this.redirectModal = false;
      this.paymentaccount = true;
    }
    else if (this.loadingprocess) {
      this.loadingprocess = true;
      this.paymentaccount = false;
      this.ojctype = false;
      this.redirectModal = false;
    }
    else if (this.totalcertificate) {
      this.totalcertificate = true;
      this.paymentaccount = false;
      this.ojctype = false;
      this.redirectModal = false;
    }
    else if (this.checkconfirm) {
      this.checkconfirm = false;
      this.paymentaccount = false;
      this.ojctype = false;
      this.paymentcheck = true;
    }
  }
  @ViewChild("webcamVideo") webcamVideo: ElementRef<HTMLVideoElement>;

  openWebcam() {
    navigator.mediaDevices
      .getUserMedia({ video: true })
      .then((stream) => {
        this.webcamVideo.nativeElement.srcObject = stream;
        this.webcamVideo.nativeElement.play();
      })
      .catch((error) => {
        if (error.name === "NotFoundError") {
          alert(error);
        } else if (error.name === "NotAllowedError") {
          alert(error);
        } else {
          alert(error);
        }
      });
  }
  ariastatus: any = false;
  backbtton(stepper: MatStepper) {
    // console.log("screen1");
    // console.log(stepper.selectedIndex);
    // console.log(this.stepperboolen);

    if (stepper.selectedIndex === 0 && this.stepperboolen == true) {
      // console.log("screen2");
      this.closeModal.nativeElement.click();
      this.resetfunc(stepper);

    }
    // else if (this.donationtypes == true) {
    //   this.donationamount = true;
    //   this.donationtypes = false;

    //  }


    else if (this.cardEditDiv == true) {
      this.cardEditDiv = false;
      this.cardDetailDiv = true;
    }

    
    else if (this.donationtypes) {
     
      this.donationtypes = false;
      this.donationamount = true;
      this.stepperboolen2 = true;
      stepper.previous();
      this.stepperboolen2 = false;

      
      // alert(this.donationamount);
      

    }

    // else if (this.repeatediv == true) {
    //  this.repeatediv = false;
    //  this.donorsearch = true;

    // }
    else if (this.donorsearch) {
      this.stepperboolen = true;
      this.donorsearch = true;
      stepper.previous();
      stepper.previous();
      this. stepperboolen2 = false;
      this. stepperboolen3 = false;
    }




    else {




      // console.log("screen3");

      this.donationamount = true;
      this.donoradd = true;
      // debugger;
      stepper.previous();
      // debugger;

      this.stepperboolen = true;
      if (this.donoradd) {
        // console.log("screen4");

        this.donorsearch = true;
        this.donoradd = false;
      }
      if (this.showScanner) {
        // console.log("screen5");

        this.showScanner = false;
        if (!this.donorid && !this.Campaigndropdown) {
          // console.log("screen6");

          this.donorsearch = true;
          this.scancertificate = false;
        } else {
          // console.log("screen7");

          this.donationtypes = true;
          this.scancertificate = false;
          stepper.next();
          stepper.next();
        }
      } else if (this.scancertificate == true) {
        // console.log("screen8");

        this.donorsearch = true;
        this.donoradd = false;
        this.scancertificate = false;
      } else if (this.schedulePledge) {
        // console.log("screen9")
        this.donationamount = true;
        this.schedulePledge = false;
        this.selectedPledge = null;
      } else if (this.donationamount) {
        // console.log("screen10");

        this.SelectedItem = null;
        this.donorsearch = true;
        this.donationamount = false;
        this.schedulePledge = false;
        stepper.previous();
      } else if (this.donationtypes) {
        // console.log("screen11");

        this.donationtypes = false;
        this.paymentMethod = false;
        this.donationamount = true;
        stepper.previous();
      } else if (this.paymentMethod) {
        // console.log("screen12");

        this.openOtherMethod();
        this.donationtypes = true;
        this.paymentMethod = false;
      } else if (
        this.repeatediv ||
        this.scancertificatediv ||
        this.followpledgediv
      ) {
        // console.log("screen13");

        this.cashdiv = false;
        this.repeatediv = false;
        this.cardDetailDiv = false;
        this.cardEditDiv = false;
        this.cardAddDiv = false;
        this.checkdiv = false;
        this.scancertificatediv = false;
        this.followpledgediv = false;
        this.openOtherMethod();
        this.donationtypes = true;
        this.paymentMethod = false;
      } else if (
        this.cashdiv ||
        this.cardDetailDiv ||
        this.cardEditDiv ||
        this.cardAddDiv ||
        this.checkdiv
      ) {
        // console.log("screen14");

        this.cashdiv = false;
        this.cardDetailDiv = false;
        this.cardEditDiv = false;
        this.cardAddDiv = false;
        this.checkdiv = false;
        this.paymentMethod = true;
        this.newCardForm.reset();
        this.editCardForm.reset();
      } else if (this.AferPyment || this.receiptScreen) {
        // console.log("screen15");

        this.selectedAmount = "";
        this.Campaigndropdown = null;
        this.paidDonationId = null;
        this.donorsearch = true;
        this.donoradd = false;
        this.AferPyment = false;
        this.receiptScreen = false;
        stepper.previous();
        stepper.previous();
      }
    }
  }

  public selectRepeateWeeks: any = [];

  public selectRepeateWeekDays: any = [
    { name: "Monday", value: "1" },
    { name: "Tuesday", value: "2" },
    { name: "Wednesday", value: "3" },
    { name: "Thrusday", value: "4" },
    { name: "Friday", value: "5" },
    { name: "Saturday", value: "6" },
    { name: "Sunday", value: "7" },
  ];

  public selectRepeateMonths: any = [];
  public dayOfEachMonth = 31;
  public monthDetail: any = [
    { name: "January", value: "January", days: 31 },
    { name: "February", value: "February", days: 28 },
    { name: "March", value: "March", days: 31 },
    { name: "April", value: "April", days: 30 },
    { name: "May", value: "May", days: 31 },
    { name: "June", value: "June", days: 30 },
    { name: "July.", value: "July", days: 31 },
    { name: "August", value: "August", days: 31 },
    { name: "September", value: "September", days: 30 },
    { name: "October", value: "October", days: 31 },
    { name: "November", value: "November", days: 30 },
    { name: "December", value: "December", days: 31 },
  ];
  public selectRepeateDay: any = [];

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
    this.resetfunc(this.stepper);
  }

  // Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Toggle sidebar open
   *
   * @param key
   */
  toggleSidebar(key): void {
    this._coreSidebarService.getSidebarRegistry(key).toggleOpen();
  }

  addNewCrdClick() {
    this.CloseAllDenationScreen();
    this.cardAddDiv = true;
    this.newCardForm.reset();
    this.submitNewCardform = false;
  }
  //temp card detail
  crdDetail: any = [
    /*
    { cardNumber: '2112111111114321', cardName: 'abc1', expDate: '21/2/2030' , status: false },//master
    { cardNumber: '5112111111110987', cardName: 'abc2', expDate: '21/2/2030', status: true },//master
    { cardNumber: '4112111111119876', cardName: 'abc3', expDate: '21/2/2030', status: true },//Visa
    { cardNumber: '3112111111118765', cardName: 'abc4', expDate: '21/2/2030', status: false },//American*/
  ];

  cardClicked(indx, evt) {
    console.log(indx);
    this.crdDetail.forEach((crd, i) => {
      (<HTMLInputElement>(
        document.getElementsByClassName("creditCrd-list-col")[i]
      )).style.border = "0px solid";
    });

    (<HTMLInputElement>(
      document.getElementsByClassName("creditCrd-list-col")[indx]
    )).style.border = "2px solid #deb887";
  }
  daysInMonth(month, year) {
    const daysInMonths = [
      31,
      year % 4 === 0 && (year % 100 !== 0 || year % 400 === 0) ? 29 : 28,
      31,
      30,
      31,
      30,
      31,
      31,
      30,
      31,
      30,
      31,
    ];
    return daysInMonths[month - 1];
  }
  openRepeateScreen() {
    this.selectRepeateWeeks.length = 0;
    var totWeek: number = Math.round(this.selectedCampaign.DaysLeft / 7);
    for (let i = 0; i < totWeek; i++) {
      !i &&
        this.selectRepeateWeeks.push({ name: i + 1 + " week", value: i + 1 });
      i &&
        this.selectRepeateWeeks.push({ name: i + 1 + " weeks", value: i + 1 });
    }
    //---------------------
    this.selectRepeateMonths.length = 0;
    var daysLeft = 365; //this.selectedCampaign.DaysLeft;
    const dtToday = new Date();
    let month = parseInt((dtToday.getMonth() + 1).toString().padStart(2, "0"));
    let year = dtToday.getFullYear();
    let count = 0;
    while (daysLeft > 0) {
      console.log(month, year, this.daysInMonth(month, year));
      let days = this.daysInMonth(month, year);
      if (days < this.dayOfEachMonth) this.dayOfEachMonth = days;
      if (daysLeft > days) daysLeft = daysLeft - days;
      else {
        days = daysLeft;
        daysLeft = 0;
      }
      count += 1;
      this.selectRepeateMonths.push({
        name: count,
        value: count,
        numDays: days,
      });

      if (month == 12) {
        month = 0;
        year += 1;
      }
      month += 1;
    }
    this.repeateType = "monthly";
    this.numberOfRepeate = null;
    this.dayOfRepeate = null;
    this.selectedRepeatDate = null;
  }

  repeateType = "monthly";
  numberOfRepeate = null;
  dayOfRepeate = null;
  selectedRepeatDate = null;
  repeateTypeChange(type) {
    this.repeateType = type;
    this.numberOfRepeate = null;
    this.dayOfRepeate = null;
    this.selectedRepeatDate = null;
  }
  repeatDateChange(evt) {
    this.selectedRepeatDate = evt.target.value;
    let campEndDate = this.selectedCampaign.EndDate;
    console.log(this.selectedRepeatDate, this.pledgeMinDueDate, campEndDate);

    if (
      parseInt(this.pledgeMinDueDate.replaceAll("-", "")) <=
      parseInt(this.selectedRepeatDate.replaceAll("-", "")) &&
      parseInt(this.selectedRepeatDate.replaceAll("-", "")) <=
      parseInt(campEndDate.replaceAll("-", ""))
    ) {
    } else if (
      parseInt(this.pledgeMinDueDate.replaceAll("-", "")) <=
      parseInt(this.selectedRepeatDate.replaceAll("-", ""))
    ) {
      this.showToast(
        "Selected date should be greater then current date",
        "error"
      );
    } else if (
      parseInt(this.selectedRepeatDate.replaceAll("-", "")) <=
      parseInt(campEndDate.replaceAll("-", ""))
    ) {
      this.showToast(
        "Selected date should be less then or equal to campaign end date i.e" +
        this.selectedCampaign.EndDate +
        " .",
        "error"
      );
    }
  }
  monthlyRepeateChange(evt) {
    this.dayOfRepeate = { value: parseInt(evt) + 1 };
  }
  setRepeatDonation(stepper) {
    if (
      !this.numberOfRepeate ||
      !this.dayOfRepeate ||
      !this.selectedRepeatDate
    ) {
      this.showToast("Fill all the required fields", "error");
    } else {
      this.repeatedPayDiv = true;
      this.CloseAllDenationScreen();
      this.donateFunc();
      stepper.next();
      this.paymentMethod = true;
      /*let obj = {
              "donorId": this.donorid,
              "campaignId": this.selectedCampaign.CampaignId,
              "organizationId": this.orgid,
              "amount": ""+this.selectedAmount,
              "repeatType": this.repeateType,
              "numberOf": this.numberOfRepeate?parseInt(this.numberOfRepeate.value):0,
              "dayOf": this.dayOfRepeate?parseInt(this.dayOfRepeate.value):0,
              "startDate": this.selectedRepeatDate
            }
            this.service.setRecurringDonation(obj).subscribe(dta=>{
              this.showToast('Recurring set successfully', 'success');
              //this.closeDonationPop(stepper);
              this.repeatedPayDiv = true;
              this.CloseAllDenationScreen();
              this.donateFunc();
              stepper.next();
              this.paymentMethod=true
            },
            (error)=>{
              this.showToast('Inter server error', 'error');
            });*/
    }
  }

  public checkImg: File = null;
  checkURL: any = null;
  onSelectCheckFile(event) {
    const file = (event.target as HTMLInputElement).files[0];
    const allowedMimeTypes = ["image/jpeg", "image/png", "image/gif"];

    if (
      event.target.files &&
      event.target.files[0] &&
      allowedMimeTypes.includes(file.type)
    ) {
      this.checkImg = file;
      var reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = (event) => {
        this.checkURL = "" + event.target.result;
        console.log(this.checkURL);
      };
    } else {
      this.showToast("Invalid file format", "error");
    }
  }

  CloseAllDenationScreen() {
    this.searchTerm = "";
    this.filterUpdate();
    this.processing = false;
    this.AferPyment = false;
    this.paymentSuccessful = false;
    this.paymentFailure = false;
    this.receiptScreen = false;

    this.donationtypes = false;
    this.paymentMethod = false;
    this.cashdiv = false;
    this.cardDetailDiv = false;
    this.cardEditDiv = false;
    this.cardAddDiv = false;
    this.checkdiv = false;
    this.AferPyment = false;

    this.smsActive = false;
    this.messageActive = false;

    this.followpledgediv = false;
    this.repeatediv = false;

    this.schedulePledge = false;
    this.donationamount = false;
    this.scanCertificateFlag = false;
    this.showScannerDonor = false;
  }
  showToast(msg, type) {
    if (type == "success") {
      this._toastrService.success(msg, "", {
        toastClass: "toast ngx-toastr",
        closeButton: true,
        timeOut: 5000,
        disableTimeOut: false,
      });
    } else {
      this._toastrService.error(msg, "", {
        toastClass: "toast ngx-toastr",
        closeButton: true,
        timeOut: 5000,
        disableTimeOut: false,
      });
    }
  }
  /*********QR CODE SCANNER**************************************** */
  showScanner: boolean = false;
  ngAfterViewInit(): void { }
  public config: ScannerQRCodeConfig = {
    constraints: {
      video: {
        width: window.innerWidth,
      },
    },
  };

  public qrCodeResult: ScannerQRCodeSelectedFiles[] = [];
  public percentage = 80;
  public quality = 100;

  @ViewChild("action") action!: NgxScannerQrcodeComponent;
  public onEvent(e: ScannerQRCodeResult[], action?: any, bool?: boolean): void {
    //e && action && action.pause();

    !bool && this.handleCamera(action, false);

    console.log(e);
    const scanResp: any = e[0];
    let scanCode = scanResp.value.split("*")[1];
    if (scanCode) {
      this.scanCertificateNum = scanCode;
    } else {
      this.showToast("Invalid QR code", "error");
    }
  }

  public myOnEvent(
    e: ScannerQRCodeResult[],
    action?: any,
    bool?: boolean
  ): void {
    // e && action && action.pause();
    // alert("myInfo");
    !bool && this.handleCamera(action, false);
    console.log(e);
    const scanResp: any = e[0];
    this.scanCode = scanResp.value.split("*")[1];
    if (this.scanCode) {
      this.scanCertificateNum = this.scanCode;
      const validCertificateRequest: any = {
        donorId: 0,
        campaignId: 0,
        // "organizationId": 13,
        organizationId: this.orgnasitionId,
        pledgeId: 0,
        dafFund: "ojc",
        clientId: 0,
        amount: 0,
        scannedBarCode: this.scanCode,
        voucherImage: "string",
      };

      this.ValidateSavedCertificatesBatch();
    } else {
      this.showToast("Invalid QR code", "error");
    }
  }

  // Method to calculate the total amount
  calculateTotalAmount(): number {
    return this.certificateItems.reduce((total, item) => {
      const amount = parseFloat(item.amount.replace(/[$,]/g, ""));
      return total + amount;
    }, 0);
  }
  openQRCodeScanner() {
    /*this.action.isReady.subscribe((res: any) => {
      //this.handleCamera(this.action, 'start');

    });*/
    this.qrCodeResult = [];
    this.donorname = null;
    this.CampaignName = null;
    this.selectedAmount = null;
    this.scanCertificateNum = null;
    this.scan64 = null;
    this.donorsearch = false;
    this.donoradd = false;
    this.scancertificate = true;
    this.showScannerManual = false;
    this.showScannerDonor = false;
    this.showScanner = true;
    this.SelectedItem = null;
    this.donorid = null;
    this.donorname = null;
    this.Campaigndropdown = null;
    //setTimeout(() => {this.handleCamera(this.action, this.showScanner)}, 500);
  }

  public handleCamera(action: any, fn: boolean): void {
    // alert('hiiii');
    const playDeviceFacingBack = (devices: any[]) => {
      // front camera or back camera check here!
      const device = devices.find((f) =>
        /back|rear|environment/gi.test(f.label)
      ); // Default Back Facing Camera
      action.playDevice(device ? device.deviceId : devices[0].deviceId);
    };

    if (fn) {
      action["start"](playDeviceFacingBack).subscribe(
        (r: any) => console.log(fn, r),
        alert
      );
    } else {
      action["stop"]().subscribe((r: any) => console.log(fn, r), alert);
    }
  }
  chooseScan(evt) {
    evt.target.value = null;
  }
  public onSelects(event, files: any) {
    this.onSelectScanFile(event);
    if (!this.scanCertificateNum && !this.showScannerManual) {
      //if sertificate is not scanned through camera
      this.qrcode
        .loadFiles(files, this.percentage, this.quality)
        .subscribe((res: ScannerQRCodeSelectedFiles[]) => {
          this.qrCodeResult = res;
        });
    }
  }
  scan64 = null;
  onSelectScanFile(event) {
    const file = (event.target as HTMLInputElement).files[0];
    const allowedMimeTypes = ["image/jpeg", "image/png", "image/gif"];
    console.log("SCAN=1=", event.target.files);
    console.log("SCAN=2=", event.target.files[0]);
    console.log("SCAN=3=", allowedMimeTypes.includes(file.type));

    if (
      event.target.files &&
      event.target.files[0] &&
      allowedMimeTypes.includes(file.type)
    ) {
      //this.dafImg = file;
      var reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = (event) => {
        this.scan64 = "" + event.target.result;
        // console.log(this.scan64);
      };
    } else {
      this.showToast("Invalid file format", "error");
    }
  }
  cancelQrImage() {
    this.qrCodeResult = [];
    this.scan64 = null;
  }

  public myHandleCamera(action: any, fn: boolean): void {
    // alert('hello');
    const playDeviceFacingBack = (devices: any[]) => {
      // front camera or back camera check here!
      const device = devices.find((f) =>
        /back|rear|environment/gi.test(f.label)
      ); // Default Back Facing Camera
      action.playDevice(device ? device.deviceId : devices[0].deviceId);
    };

    if (fn) {
      // alert('hello2');
      action["start"](playDeviceFacingBack).subscribe(
        (r: any) => console.log(fn, r),
        alert
      );
    } else {
      action["stop"]().subscribe((r: any) => console.log(fn, r), alert);
    }
  }
  url: string = "";
  logoURL: string | null = null;
  file: File | null = null;
  image: any;
  onSelectFileImage(event: Event): void {
    this.url = "";
    const input = event.target as HTMLInputElement;
    const file = input.files ? input.files[0] : null;

    if (file) {
      const allowedMimeTypes = ["image/jpeg", "image/png", "image/gif"];
      if (allowedMimeTypes.includes(file.type)) {
        this.file = file;
        const reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onload = () => {
          this.logoURL = reader.result as string;
          this.url = this.logoURL;
          this.imageUrl = this.logoURL;

          // const certificateId = 2;
          this.voucherImage = this.url;
          this.image = this.url;
        };
      } else {
        this.showToast("Invalid file format", "error");
      }
    }
  }

  updateCertificateImageBatchClick() {
    this.service
      .updateCertificateImageBatchApi(
        this.certificateId,
        this.voucherImage,
        this.status
      )
      .subscribe(
        (response: any) => {
          console.log(
            "Certificate image batch updated successfully:",
            response
          );
          console.log(JSON.stringify(response));
          // Handle the response here, e.g., update the UI or notify the user
        },
        (error) => {
          console.error("Error updating certificate image batch:", error);
          // Handle the error, e.g., show an error message to the user
        }
      );
  }
  /************************************************* */
  /*******************************pledge********************** */
  pledegeDueDateChange() {
    console.log(
      "pledge due date change===",
      this.pledgeDueDate,
      this.pledgeMinDueDate
    );
    if (this.pledgeDueDate < this.pledgeMinDueDate)
      this.showToast("Due date should be greater then current date", "error");
    else if (this.pledgeDueDate < this.pledgeFollowupDate)
      this.showToast("Due date should be greater then follow up date", "error");
  }
  followupDateChange() {
    console.log(
      "follow up date change===",
      this.pledgeDueDate,
      this.pledgeMinDueDate
    );
    if (this.pledgeFollowupDate < this.pledgeMinDueDate)
      this.showToast(
        "Follow up date should be greater then current date",
        "error"
      );
    else if (this.pledgeFollowupDate > this.pledgeDueDate)
      this.showToast(
        "Follow up date should be less then pledge due date",
        "error"
      );
  }
  @ViewChild("closeModal") closeModal: ElementRef;
  createNewPledge(stepper) {
    if (!this.pledgeDueDate || !this.pledgeMinDueDate)
      this.showToast("Fill all the required fields", "error");
    else if (this.pledgeDueDate < this.pledgeMinDueDate)
      this.showToast("Due date should be greater then current date", "error");
    else if (this.pledgeDueDate < this.pledgeFollowupDate)
      this.showToast("Due date should be greater then follow up date", "error");
    else if (this.pledgeFollowupDate < this.pledgeMinDueDate)
      this.showToast(
        "Follow up date should be greater then current date",
        "error"
      );
    else if (this.pledgeFollowupDate > this.pledgeDueDate)
      this.showToast(
        "Follow up date should be less then pledge due date",
        "error"
      );
    else {
      let obj = {
        DonorId: this.donorid,
        CampaignId: this.Campaigndropdown,
        OrganizationId: this.orgid,
        Amount: "" + this.selectedAmount,
        DueDate: this.pledgeDueDate,
        FollowsUpDate: this.pledgeFollowupDate,
        OrganizationName: "abc",
        CampaignName: this.CampaignName,
      };
      this.service.savePledge(obj).subscribe(
        (res) => {
          this.showToast("Pledge created successfully", "success");
          this.updateDashboard();
          this.resetfunc(stepper);
          this.closeDonationPop(stepper);
        },
        (error) => {
          this.showToast("Internal server error!", "error");
          this.resetfunc(stepper);
          this.closeDonationPop(stepper);
        }
      );
    }
  }

  getDonorPledgeList() {
    let obj = {
      DonorId: this.donorid,
      CampaignId: this.Campaigndropdown,
      OrganizationId: this.orgid,
    };

    this.service.getPledgeList(obj).subscribe(
      (res) => {
        this.pledgeList = res.d;

        !this.pledgeList.length && this.showToast("No pledge", "error");
        this.pledgeList.forEach((dta) => {
          dta.DueDate = dta.DueDate.split(" ")[0];
        });
      },
      (error) => {
        this.showToast("Internal server error!", "error");
      }
    );
  }
  payPledgeAmount(stepper) {
    if (!this.selectedAmount || parseInt(this.selectedAmount) <= 0 || !this.selectedPledge ) {
    // if (!this.selectedAmount || parseInt(this.selectedAmount) <= 0 ) {
      this.showToast("Please select one pledge", "error");
      return;

    } else if (Number(this.selectedAmount) > Number(this.campaigngoal)) {
       this.showToast(
        `Amount exceeds campaign goal ${this.campaigngoal.toLocaleString()}`,
        "error"
      );
      return;

    }
    else {
      this.CloseAllDenationScreen();
      this.donateFunc();
      stepper.next();
      this.paymentMethod = true;
    }
  }
  getCurrentTime() {
    var date = new Date();
    var hours: any = date.getHours();
    var minutes: any = date.getMinutes();
    var ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    var strTime = hours + ":" + minutes + " " + ampm;
    return strTime;
  }

  closeDonationPop(stepper) {
    stepper.previous();
    stepper.previous();
    this.newdonorform.reset();
    this.donoradd = true;
    this.donorsearch = true;
    this.donoradd = false;
    this.closeModal.nativeElement.click();
  }

  getFullAddress(locations) {
    let location = null;
    let address = "";
    locations.length && (location = locations[0]);

    location &&
      Object.keys(location).forEach((kys) => {
        location[kys] && (address += " " + location[kys]);
      });
    return address;
  }

  formatCardNumberFill(value: string): string {
    // Remove all non-numeric characters
    const digits = value.replace(/\D/g, '');

    // Format digits into groups of four
    const formatted = digits.match(/.{1,4}/g)?.join(' ') || '';

    return formatted;
  }

  onCardNumberChange(event: Event): void {
    const input = event.target as HTMLInputElement;
    const formattedValue = this.formatCardNumberFill(input.value);
    input.value = formattedValue;
    // Update form control value
    this.editCardForm.get('cardNumber')?.setValue(formattedValue, { emitEvent: false });
  }

  cardNumberChange(evt) {
    const keyVal = evt.key;
    let val = evt.target.value.replaceAll(" ", "");
    let fullStr = "";
    let valArr = val.split("");
    let arr = [];
    valArr.forEach((dta) => {
      if (parseInt(dta) >= 0 && parseInt(dta) <= 9) arr.push(dta);
    });
    valArr = arr.concat();

    valArr.forEach((dta, indx) => {
      if (indx > 2 && !((indx + 1) % 4)) fullStr += dta + " ";
      else fullStr += dta;
    });
    evt.target.value = fullStr;
  }

  // cardNumberChange(evt) {
  //   const keyVal = evt.key;
  //   let val = evt.target.value.replace(/\s+/g, ''); // Remove all spaces
  //   let fullStr = "";
  //   let valArr = val.split("");
  //   let arr = [];

  //   // Filter out non-numeric characters
  //   valArr.forEach((dta) => {
  //     if (!isNaN(parseInt(dta))) arr.push(dta);
  //   });

  //   valArr = arr.concat();

  //   // Format the card number with spaces every 4 digits
  //   valArr.forEach((dta, indx) => {
  //     if (indx > 0 && indx % 4 === 0) fullStr += " "; // Add space after every 4 digits
  //     fullStr += dta;
  //   });

  //   evt.target.value = fullStr;

  //   // Update the form control value
  //   // this.crdEForm.get('cardNumber').setValue(fullStr, { emitEvent: false });
  // }


  expDateChange(evt) {
    console.log(evt.target.value, evt.key);
    const keyVal = evt.key;
    let valArr = evt.target.value.split("");

    let arr = [];
    valArr.forEach((dta) => {
      if (parseInt(dta) >= 0 && parseInt(dta) <= 9) arr.push(dta);
    });
    valArr = arr.concat();
    evt.target.value = valArr.join("");

    if (valArr.length >= 3) {
      evt.target.value =
        valArr[0] +
        valArr[1] +
        "/" +
        valArr[2] +
        (valArr[3] ? valArr[3] : "") +
        (valArr[4] ? valArr[4] : "") +
        (valArr[5] ? valArr[5] : "");
    } else if (evt.key == "/") {
      if (valArr.length == 2) {
        evt.target.value = "0" + valArr[0] + "/";
      } else if (valArr.length > 3) {
        evt.target.value = evt.target.value.slice(
          0,
          evt.target.value.length - 1
        );
      }
    }
    /*else if(keyVal == valArr[valArr.length-1]){
      evt.target.value = evt.target.value.slice(0, valArr.length-1)
    }*/
  }
  get crdEForm(): { [key: string]: AbstractControl } {
    return this.editCardForm.controls;
  }
  get crdForm(): { [key: string]: AbstractControl } {
    return this.newCardForm.controls;
  }

  processBtndeleteCartInfo = false;
  deleteCard(stepper, cardSelected) {
    this.service
      .deleteCard({ token: this.crdDetail[cardSelected].token })
      .subscribe((dta: any) => {
        if (dta.success == "SUCCESS") {
          this.processBtndeleteCartInfo = true;
          this.newCardForm.reset();
          this.submitNewCardform = false;
          this.editCardForm.reset();
          this.submitEditCardform = false;
          this.showToast(dta.message, "success");
          this.cardEditDiv = false;
          this.cardDetailDiv = true;
          this.crdDetail.splice(cardSelected, 1);
          this.cardSelected = null;

          //this.closeDonationPop(stepper);
        } else {
          this.showToast("Card not deleted.", "error");
        }
      });
  }

  numberValueNewland:any;
  numberValueNew:any;

  editCardSubmit(stepper, cardSelected, save) {



    this.submitEditCardform = true;
    if (this.editCardForm.invalid && this.crdEdit) {
      let value: string = this.editCardForm.get('cardNumber')?.value;
      let length: number = value.length;
      if (length != 19) {
      this.showToast("Invalid card", "error");
      return;
    }
  } else if (this.cardEditDiv== true)   {






   
      

    const crdExp = this.editCardForm.controls.expiryDate.value.split("/");
    const d = new Date();
    let month = d.getMonth() + 1;
    let year = d.getFullYear();
    console.log(
      month,
      year,
      this.editCardForm.controls.expiryDate.value.split("/")
    );
    if (
      year > parseInt(crdExp[1]) ||
      (year == parseInt(crdExp[1]) && month > parseInt(crdExp[0])) ||
      parseInt(crdExp[0]) > 12
    ) {
      this.showToast("Invalid expiry date", "error");
      return;
    }
    // console.log(this.editCardForm.controls.cardNumber.value)
    // console.log(this.editCardForm.controls.cardName.value)
    // console.log(this.editCardForm.controls.cardCvv.value)
    // console.log(this.editCardForm.controls.expiryDate.value)
    const crdName = this.editCardForm.controls.cardName.value.split(" ");
    this.crdDetail[cardSelected].cardName =
      this.editCardForm.controls.cardName.value;
    this.crdDetail[cardSelected].cardNumber =
      this.editCardForm.controls.cardNumber.value;
    this.crdDetail[cardSelected].cardCvv =
      this.editCardForm.controls.cardCvv.value;
    this.crdDetail[cardSelected].expDate =
      this.editCardForm.controls.expiryDate.value;
    let obj: any = null;
    if (!this.repeatedPayDiv) {
      // editCardSubmit 
      //payment is not for recurring(repeate)
      this.newCardNumber = this.crdDetail[cardSelected].cardNumber;
      obj = {
        cardNum: this.crdDetail[cardSelected].cardNumber,
        exp: crdExp[0] + crdExp[1].split("")[2] + crdExp[1].split("")[3],
        token: this.crdDetail[cardSelected].token,
        cvv: "" + this.crdDetail[cardSelected].cardCvv,
        street: "",
        zip: "",
        billFirstName: crdName[0],
        billMiddleName: crdName.length == 3 ? crdName[1] : "",
        billLastName:
          crdName.length == 3
            ? crdName[2]
            : crdName.length == 2
              ? crdName[1]
              : "",
        saveCard: true,
        pledgeId: this.selectedPledge ? this.selectedPledge : 0,
        donorId: this.SelectedItem,
        organizationId: this.orgid,
        campaignId: this.Campaigndropdown,
      };
    } else {
      //payment is for recurring(repeate)
      this.cardDonationForRecurring(
        this.crdDetail[cardSelected].cardNumber,
        this.editCardForm.controls.cardName.value,
        stepper,
        crdExp[0] + crdExp[1].split("")[2] + crdExp[1].split("")[3],
        this.crdDetail[cardSelected].cardCvv,
        this.crdDetail[cardSelected].token,
        "card"
      );
    }
    if (save) {
      this.service.saveCard(obj).subscribe((dta: CardResponse) => {
        console.log("datadata:-",dta)
        if (dta.newToken == null) {
          this.showToast("Invalid card", "error");
          return;
    
        }
    
        this.crdEdit = !this.crdEdit;
      });
    } else if (!this.repeatedPayDiv) {
      //payment is not through repeate
      obj.amount = "" + this.selectedAmount;
      console.log(obj);
      this.callAddCrdDonation(obj, stepper);
    }
  }
}

  newCardSubmit(stepper) {

    let value: string = this.newCardForm.get('cardNumber')?.value;
    let valuecvv: string = this.newCardForm.get('cardCvv')?.value.toString();
    if (!value) {
      this.showToast("Please Fill card details", "error");
      return;
    }
    // alert(typeof(valuecvv));
    let Cardlength: number = value.length;
    // alert(length);
    // alert("valuecvv"+valuecvv);
    let lengthcvv: number = valuecvv.length;
    // alert("valuecvvlenfth"+lengthcvv);
    if (Cardlength != 19) {
      this.showToast("Invalid card", "error");
      return;
    }

    if (lengthcvv != 3) {
      this.showToast("Invalid CVV", "error");
      return;
    }

    this.submitNewCardform = true;
    if (this.newCardForm.invalid) return;

    const crdExp = this.newCardForm.controls.expiryDate.value.split("/");
    const d = new Date();
    let month = d.getMonth() + 1;
    let year = d.getFullYear();
    console.log(
      month,
      year,
      this.newCardForm.controls.expiryDate.value.split("/")
    );
    if (
      year > parseInt(crdExp[1]) ||
      (year == parseInt(crdExp[1]) && month > parseInt(crdExp[0])) ||
      parseInt(crdExp[0]) > 12
    ) {
      this.showToast("Invalid expiry date", "error");
      return;
    }
    // console.log(this.newCardForm.controls.cardNumber.value)
    // console.log(this.newCardForm.controls.cardName.value)
    // console.log(this.newCardForm.controls.expiryDate.value)
    // console.log(this.newCardForm.controls.cardCvv.value)
    // console.log(this.newCardForm.controls.cardSave.value)
    const crdName = this.newCardForm.controls.cardName.value.split(" ");

    if (!this.repeatedPayDiv) {
      //payment is not for recurring(repeate){
      this.newCardNumber = this.newCardForm.controls.cardNumber.value.replaceAll(" ", "");
      this.numberValueNewland = this.newCardForm.controls.cardNumber.value;
      this.numberValueNew = this.formatCardNumber(this.numberValueNewland);



      // alert(this.newCardNumber);
      // alert(this.numberValueNew);
      let numberValue: number = this.newCardForm.controls.cardCvv.value;
      let lengthOfNumber: number = numberValue.toString().length;
      console.log("cvvLenthCheck", lengthOfNumber);
      console.log("cvvValue", numberValue);
      if (lengthOfNumber < 3 || lengthOfNumber > 3) {
        this.showToast("Invalid CVV", "error");
        return;

      }

      let obj = {
        cardNum: this.newCardForm.controls.cardNumber.value.replaceAll(" ", ""),
        exp: crdExp[0] + crdExp[1].split("")[2] + crdExp[1].split("")[3],
        amount: "" + this.selectedAmount,
        cvv: "" + this.newCardForm.controls.cardCvv.value,
        street: "",
        zip: "",
        billFirstName: crdName[0],
        billMiddleName: crdName.length == 3 ? crdName[1] : "",
        billLastName:
          crdName.length == 3
            ? crdName[2]
            : crdName.length == 2
              ? crdName[1]
              : "",
        saveCard: !this.newCardForm.controls.cardSave.value ? false : true,
        pledgeId: this.selectedPledge ? this.selectedPledge : 0,
        donorId: this.SelectedItem,
        organizationId: this.orgid,
        campaignId: this.Campaigndropdown,
      };
      this.callAddCrdDonation(obj, stepper);
    } else {
      this.cardDonationForRecurring(
        this.newCardForm.controls.cardNumber.value.replaceAll(" ", ""),
        this.newCardForm.controls.cardName.value,
        stepper,
        crdExp[0] + crdExp[1].split("")[2] + crdExp[1].split("")[3],
        this.newCardForm.controls.cardCvv.value,
        "",
        "card"
      );
    }
  }

  

  paymentInprogress: boolean = false;
  cardDonationForRecurring(
    crdNum,
    crdName,
    stepper,
    crdExp,
    cvv,
    crdToken,
    paymentMethod
  ) {
    if (this.paymentInprogress) return;

    let obj = {
      repeatId: 0, //this.paidDonationId,
      donorId: this.SelectedItem,
      campaignId: this.Campaigndropdown,
      organizationId: this.orgid,
      amount: "" + this.selectedAmount,
      repeatType: this.repeateType,
      numberOf: this.numberOfRepeate ? parseInt(this.numberOfRepeate.value) : 0,
      dayOf: this.dayOfRepeate ? parseInt(this.dayOfRepeate.value) : 0,
      startDate: this.selectedRepeatDate, ///
      paymentMethod: paymentMethod,
      cardToken: crdToken,
      cardNo: crdNum,
      exp: crdExp,
      cvv: cvv,
      nameOnCard: crdName,
      checkNumber: this.checkNumber,
      checkImage: this.checkURL,
    };

    this.paymentInprogress = true;
    this.service.addRecurringCardDonation(obj).subscribe(
      (result) => {
        this.newCardForm.reset();
        this.paymentInprogress = false;
        if (result.success) {
          this.updateDashboard();
          this.SelectedItem = 0;
          this.showToast("Recurring saved successfully", "success");
          this.closeDonationPop(stepper);
        } else if (!result.success) {
          this.showToast(result.d.message, "error");
        }
      },
      (error) => {
        this.paymentInprogress = false;
        this.showToast("Internal server error", "error");
      }
    );
  }
  callAddCrdDonation(obj, stepper) {
    if (this.paymentInprogress) return;

    this.paymentInprogress = true;
    this.service.addCardDonation(obj).subscribe(
      (dta) => {
        this.paymentInprogress = false;
        this.newCardForm.reset();
        if (dta.success == "SUCCESS") {
          this.showToast(dta.message, "success");
          // this.donorId.push(this.SelectedItem)
          this.newCardForm.reset();
          this.cardAddDiv = false;
          this.submitNewCardform = false;
          this.editCardForm.reset();
          this.submitEditCardform = false;
          this.cardEditDiv = false;
          this.AferPyment = true;
          this.paymentSuccessful = true;
          this.paymentFailure = false;
          this.paidDonationId = dta.id;
          this.updateDonationList();
          this.updateDashboard();
        } else if (dta.success != "SUCCESS") {
          this.showToast(dta.message, "error");
          this.AferPyment = true;
          this.cardEditDiv = false;
          this.paymentSuccessful = false;
          this.paymentFailure = true;
          this.cardAddDiv = false;
        }
      },
      (error) => {
        this.paymentInprogress = false;
        this.showToast("Internal server error.", "error");
      }
    );
  }
  openOtherMethod() {
    this.donationtypes = true;
    this.repeatedPayDiv = false;
  }
  cancelEditMode() {
    this.crdEdit = false;
    this.editCardForm.reset();
    this.submitEditCardform = false;
    this.editCardForm.setValue({
      cardName: this.crdDetail[this.cardSelected].cardName,
      cardNumber: this.crdDetail[this.cardSelected].cardNumber,
      cardCvv: this.crdDetail[this.cardSelected].cardCvv,
      expiryDate: this.crdDetail[this.cardSelected].expDate,
    });
  }
  openEditMode() {
    this.crdEdit = !this.crdEdit;
    this.editCardForm.setValue({
      cardName: this.crdDetail[this.cardSelected].cardName,
      cardNumber: "",
      cardCvv: "",
      expiryDate: this.crdDetail[this.cardSelected].expDate,
    });
  }
  openEditCrd() {
    if (this.cardSelected == null) {
      this.showToast("No card selected", "error");
    } else {
      this.CloseAllDenationScreen();
      this.cardEditDiv = true;
      this.editCardForm.setValue({
        cardName: this.crdDetail[this.cardSelected].cardName,
        cardNumber: this.crdDetail[this.cardSelected].cardNumber,
        cardCvv: this.crdDetail[this.cardSelected].cardCvv,
        expiryDate: this.crdDetail[this.cardSelected].expDate,
      });
    }
  }

  

  resetManualScan() {
    this.selectedDaf = null;
    this.scanCertificateNum = null;
    this.resetCamScan();
  }
  resetCamScan() {
    this.qrCodeResult = [];
    this.scan64 = null;
    this.scanCertificateNum = null;
  }
  checkAndSaveScanCertiManual(stepper) {
    if (!this.selectedDaf || !this.scanCertificateNum || !this.scan64) {
      this.showToast("Please fill all the fields", "error");
      this.scanCertificateFlag = false;
    } else {
      this.saveScanCertificate(stepper);
    }
  }
  checkAndSaveScanCertificate(stepper) {
    if (!this.selectedDaf || !this.scanCertificateNum || !this.scan64) {
      this.showToast("Please fill all the fields", "error");
      this.scanCertificateFlag = false;
    } else {
      this.validateSertificate(stepper);
    }
  }
  scanCertificateCheck(stepper) {
    if (!this.scanCertificateNum || !this.scan64) {
      this.showToast("Choose certificate image", "error");
      this.scanCertificateFlag = false;
      return;
    } else {
      this.validateSertificate(stepper);
    }
  }
  validateSertificate(stepper) {
    if (this.processing) return;
    else this.processing = true;
    //if(this.donorid && this.Campaigndropdown){
    const tempObj = {
      donorId: this.donorid ? this.donorid : 0,
      campaignId: this.Campaigndropdown ? this.Campaigndropdown : 0,
      organizationId: this.orgid,
      pledgeId: this.selectedPledge ? this.selectedPledge : 0,
      dafFund: this.selectedDaf ? this.selectedDaf : "OJC",
      clientId: 0,
      amount: 0,
      scannedBarCode: "" + this.scanCertificateNum,
      voucherImage: this.scan64,
    };
    this.service.validateCertificateDetails(tempObj).subscribe(
      (dta) => {
        if (dta.success == true) {
          this.donationamount = false;
          this.schedulePledge = false;
          this.updateDashboard();

          this.donorsearch = false;
          this.donoradd = false;

          this.scancertificate = true;
          this.showScanner = false;
          this.showScannerManual = false;
          this.showScannerDonor = true;
          stepper.previous();
          this.scannerDonorList = dta.d;
          this.showToast(this.scannerDonorList.responseData.message, "success");

          this.setAmount(dta.d.vocuherDetail.fullVoucher.voucherValue + "");
          if (this.scannerDonorList.donorData.length == 1) {
            this.donorid = this.scannerDonorList.donorData.donorId;
            this.donorname = this.scannerDonorList.donorData.donorName;
          }
        } else {
          if (dta.d.responseData)
            this.showToast(dta.d.responseData.message, "error");
          else if (dta.d.message) this.showToast(dta.d.message, "error");
        }
        this.processing = false;
      },
      (error) => {
        console.log(error);
        this.showToast("Internal server error", "error");
        this.processing = false;
      }
    );
    //}
  }
  processScanCertificate(stepper) {
    if (!this.donorid) {
      this.showToast("Please choose one donor", "error");
      return;
    } else {
      this.selectDonorCampaign(stepper);
    }
  }
  saveScanCertificate(stepper) {
    if (!this.scanCertificateNum || !this.scan64) {
      this.showToast("Choose certificate image", "error");
      this.scanCertificateFlag = false;
      return;
    }
    if (this.processing) return;
    else this.processing = true;
    const obj = {
      donorId: this.donorid ? this.donorid : 0,
      campaignId: this.Campaigndropdown ? this.Campaigndropdown : 0,
      organizationId: this.orgid,
      pledgeId: this.selectedPledge ? this.selectedPledge : 0,
      dafFund: this.selectedDaf ? this.selectedDaf : "OJC",
      clientId: 0,
      amount: 0,
      scannedBarCode: "" + this.scanCertificateNum,
      voucherImage: this.scan64,
    };


    this.service.saveCertificateDetails(obj).subscribe((dta) => {
      if (dta.success) {
        this.showToast(dta.d.message, "success");
      } else {
        this.showToast(dta.d.message, "error");
      }
      this.processing = false;
      this.CloseAllDenationScreen();
      this.closeDonationPop(stepper);
    });
  }
  selectDonorCampaign(stepper) {
    this.CampaignListAPi();
    this.scanCertificateFlag = true;
    this.donationamount = true;
    this.showScanner = false;
    this.showScannerManual = false;
    stepper.next();
  }
  scanDonorClick(evt, donor, indx) {
    this.scannerDonorList.donorData.forEach((dta, i) => {
      this.document
        .getElementsByClassName("scan-donorBox")
      [i].classList.remove("selectDonor");
    });
    this.document
      .getElementsByClassName("scan-donorBox")
    [indx].classList.add("selectDonor");
    this.donorid = donor.donorId;
    this.donorname = donor.donorName;
  }
  updateDonationList() {
    this.newCardNumber = null;
    this.cardSelected = null;
    this.service.update_DonationData.next('update donation dashboard==');
  }
  updateDashboard(){
    this.service.update_Dashboard.next('update dashboard===');
  }
  cardNameChange() {
    let val = this.newCardForm.controls.cardName.value;
    if (val) {
      this.newCardForm.patchValue({
        cardName: val.replace(/[^a-zA-Z\s]+/, '')
      });
    }

    val = this.editCardForm.controls.cardName.value;
    if (val) {
      this.editCardForm.patchValue({
        cardName: val.replace(/[^a-zA-Z\s]+/, '')
      });
    }
  }

  otherMothodFromScan(stepper) {
    if (!this.donorid && !this.Campaigndropdown) {
      this.donorsearch = true;
      this.scancertificate = false;
    } else {
      this.donationtypes = true;
      this.scancertificate = false;
      stepper.next();
      stepper.next();
    }
  }
  
  calculateChangeDue(){
    const due = parseInt(this.selectedTotalPledge)-parseInt(this.selectedAmount);
    if(parseInt(this.selectedPledge)){
      if(due>-1)
        this.changeDueAmount = ''+due;
    } 
  }
  getUserDetails(taxId: string) {
    console.log('inside account gaurav', taxId);
    this.service.getUserDetailsByTaxID(taxId).subscribe(
      (response: any) => {
        if (response && response.length > 0) {
          this.userDetails = response;
          this.userBankaccountDetails = response[0].BankAccounts;
          console.log('inside account gaurav', this.userBankaccountDetails);
          // Find the default account
          const defaultAccount = this.userBankaccountDetails.find(
            (account) => account.IsDefault
          );
          if (defaultAccount) {
            this.selectedAccNum = defaultAccount.AccNum;
            this.IsDefaultAccount = true;
            this.showMessage = false;
            // this.showMessage = false; // Hide the message
          } else {
            // Find the first active account if no default is set
            const firstActiveAccount = this.userBankaccountDetails.find(
              (account) => account.IsActive
            );
            if (firstActiveAccount) {
              this.selectedAccNum = firstActiveAccount.AccNum;
              this.selectedBankNameACC = firstActiveAccount.BankName;
              this.IsDefaultAccount = false;
            }
          }
        }
      },
      (error) => {
        console.error("Error fetching user details:", error);
      }
    );
  }

  certificateNo;
  validateCertificateBatch(): void {
    const allData = JSON.parse(this.currentUserCirtificateAllDataObject);
    const donorId = allData.response.DonorId;
    const campaignId = allData.response.CampaignId;
    const organizationId = allData.response.OrganizationId;
    const pledgeId = allData.response.PledgeId;
    const dafFund = allData.response.DAFFund;
    const clientId = allData.response.ClientID;
    const amount = allData.response.VoucherValue;
    const scannedBarCode = allData.response.CertificateNo;
    const voucherImage = allData.response.VoucherImage;
    const VoucherCode = allData.response.VoucherCode;

    const myobj: any = {
      donorId: donorId,
      campaignId: campaignId,
      organizationId: organizationId,
      pledgeId: pledgeId,
      dafFund: dafFund,
      clientId: clientId,
      amount: amount,
      scannedBarCode: scannedBarCode,
      voucherImage: voucherImage,
    };
    const completeScan = [scannedBarCode];
    this.service.getAllcertificate(completeScan).subscribe((res) => {
      this.checkScanStatus = res[0].Status;
      this.certificatenofordelete = res[0].CertificateId;
    });
    this.service.validateCertificateBatch(myobj).subscribe(
      (response: any) => {
        if (response.success == false) {
          this._toastrService.error(
            "Donor details not found for the selected certificate.",
            "",
            {
              toastClass: "toast ngx-toastr",
              closeButton: true,
              timeOut: 5000,
              disableTimeOut: false,
            }
          );
        } else {
          const certificateDonorId = response.d.certificateDetails.donorId;
          const onlyDonorId = response.d.donorData[0].donorId;
          if (certificateDonorId != onlyDonorId) {
            this._toastrService.error(
              "Donor details not found for the selected certificate.",
              "",
              {
                toastClass: "toast ngx-toastr",
                closeButton: true,
                timeOut: 5000,
                disableTimeOut: false,
              }
            );
          } else {
            const certificateNoForArray =
              response.d.vocuherDetail.voucherInfo.voucherNo;
            const saveCoucherCodeArray = [];
            this.certificateItems.forEach((row) => {
              saveCoucherCodeArray.push(row.code);
            });


            if (this.certificateItems.length != 0) {
              if (
                saveCoucherCodeArray.includes(certificateNoForArray) == true
              ) {
                this._toastrService.error("Certificate already added.", "", {
                  toastClass: "toast ngx-toastr",
                  closeButton: true,
                  timeOut: 5000,
                  disableTimeOut: false,
                });
              } else {
                if (this.checkScanStatus == "Complete") {
                  this._toastrService.error(
                    "Certificate already processed.",
                    "",
                    {
                      toastClass: "toast ngx-toastr",
                      closeButton: true,
                      timeOut: 5000,
                      disableTimeOut: false,
                    }
                  );
                } else {
                  this.vocuherStatusBatch =
                    response.d.vocuherDetail.voucherInfo.voucherStatus; //3
                  this.vocuherImageStatusBatch =
                    response.d.vocuherDetail.imageRequired; // false

                  let newItem;

                  if (
                    this.vocuherStatusBatch == 3 &&
                    this.vocuherImageStatusBatch == false
                  ) {
                    newItem = {
                      status: "Ready to submit",
                      amount: "$" + amount, // Replace with the actual amount from the response
                      code: VoucherCode, // Replace with the actual code from the response
                      warning: false,
                      className: "readyTosubmit",
                    };
                  } else if (
                    this.vocuherStatusBatch == 3 &&
                    this.vocuherImageStatusBatch == true
                  ) {
                    newItem = {
                      status: "Incomplete DAF",
                      amount: "$" + amount, // Replace with the actual amount from the response
                      code: VoucherCode, // Replace with the actual code from the response
                      warning: true,
                      className: "photoRequire",
                    };
                  } else {
                    this._toastrService.error(response.d.message, "", {
                      toastClass: "toast ngx-toastr",
                      closeButton: true,
                      timeOut: 5000,
                      disableTimeOut: false,
                    });
                    return; // Exit early if there's an error
                  }

                  this.certificateItems.push(newItem);
                }
              }
            } else {
              if (this.checkScanStatus == "Complete") {
                this._toastrService.error(
                  "Certificate already processed.",
                  "",
                  {
                    toastClass: "toast ngx-toastr",
                    closeButton: true,
                    timeOut: 5000,
                    disableTimeOut: false,
                  }
                );
              } else {
                this.vocuherStatusBatch =
                  response.d.vocuherDetail.voucherInfo.voucherStatus; //3
                this.vocuherImageStatusBatch =
                  response.d.vocuherDetail.imageRequired; // false
                let newItem;

                if (
                  this.vocuherStatusBatch == 3 &&
                  this.vocuherImageStatusBatch == false
                ) {
                  newItem = {
                    status: "Ready to submit",
                    amount: "$" + amount, // Replace with the actual amount from the response
                    code: VoucherCode, // Replace with the actual code from the response
                    warning: false,
                    className: "readyTosubmit",
                  };
                } else if (
                  this.vocuherStatusBatch == 3 &&
                  this.vocuherImageStatusBatch == true
                ) {
                  newItem = {
                    status: "Incomplete DAF",
                    amount: "$" + amount, // Replace with the actual amount from the response
                    code: VoucherCode, // Replace with the actual code from the response
                    warning: true,
                    className: "photoRequire",
                  };
                } else {
                  this._toastrService.error(response.d.message, "", {
                    toastClass: "toast ngx-toastr",
                    closeButton: true,
                    timeOut: 5000,
                    disableTimeOut: false,
                  });
                  return; // Exit early if there's an error
                }

                this.certificateItems.push(newItem);
              }
            }
          }
        }
      },

      (error) => {
        this._toastrService.error("Invalid Certificate", "", {
          toastClass: "toast ngx-toastr",
          closeButton: true,
          timeOut: 5000,
          disableTimeOut: false,
        });
        // alert("error");
        // console.error('Error fetching settings:', error);
      }
    );
  }

  //
  ValidateSavedCertificatesBatch(): void {
    const myobj: any = {
      organizationId: this.orgnasitionId || this.dtadonor[0].OrganizationId,
      scannedBarCode: this.scanCode,
      // "scannedBarCode": '000019799000001114',
    };

    this.service
      .validateSavedCertificates(myobj.organizationId, myobj.scannedBarCode)
      .subscribe(
        (response: any) => {
          if (response.success) {
            localStorage.setItem(
              "validateSavedCertificatesBatchData",
              JSON.stringify(response)
            );
            const CertificateNo: string[] = this.certificatearray.map(row => row.response.CertificateNo);
            // console.log('CertificateNo',CertificateNo);
            if (CertificateNo[0] == undefined) {
              this.certificatearray.push(response);
            } else {
              if (CertificateNo.includes(response.response.CertificateNo)) {
                console.log('exits');

              } else {
                this.certificatearray.push(response);
                // CertificateNo.push(response.CertificateNo);
              }
            }


            // console.log('this.certificatearray', this.certificatearray);


            this.currentUserCirtificateAllDataObject =
              this.service.getvalidatecirtificateDatafromLocalstorage();
            this.validateCertificateBatch();
          } else if (response.success == false) {
            this._toastrService.error("Invalid Certificate", "", {
              toastClass: "toast ngx-toastr",
              closeButton: true,
              timeOut: 5000,
              disableTimeOut: false,
            });
          } else {
            this._toastrService.error("Invalid Certificate", "", {
              toastClass: "toast ngx-toastr",
              closeButton: true,
              timeOut: 5000,
              disableTimeOut: false,
            });
          }
        },

        (error) => {
          this._toastrService.error("Invalid Certificate", "", {
            toastClass: "toast ngx-toastr",
            closeButton: true,
            timeOut: 5000,
            disableTimeOut: false,
          });
        }
      );
  }

  backURl() { }
  currentUserCirtificateAllDataObjectdata: any;
  certificatearray: any[] = [];

  saveCertificateData(): void {
    const donorId = this.certificatemanualEntryFormGroup.get("donorId")?.value;
    const certificateNumber =
      this.certificatemanualEntryFormGroup.get("certificateNumber")?.value;
    const amount = this.certificatemanualEntryFormGroup.get("amount")?.value;

    this.service.saveCertificate(donorId, certificateNumber, amount).subscribe({
      next: (response) => {
        if (response.success == false) {
          if (response.response == undefined) {
            this.showToast(
              "Donor details not found for the selected certificate.",
              "error"
            );
          } else {
            this.showToast(response.message, "error");
          }
        } else {
          this.certificatearray.push(response);
          this.certificateno = response.response.CertificateId;
          this.certificatemanualEntryFormGroup.reset();
          localStorage.setItem(
            "validateSavedCertificatesBatchData",
            JSON.stringify(response)
          );
          this.currentUserCirtificateAllDataObjectdata =
            this.service.getvalidatecirtificateDatafromLocalstorage();
          this.validateCertificateBatchManual();
        }
      },
      error: (error) => {
        console.error("Error saving certificate data:", error);
      },
    });
  }

  // validateCertificateBatchManual

  validateCertificateBatchManual(): void {
    const allData = JSON.parse(this.currentUserCirtificateAllDataObjectdata);
    const donorId = allData.response.DonorId;
    const campaignId = allData.response.CampaignId;
    const organizationId = allData.response.OrganizationId;
    const pledgeId = allData.response.PledgeId;
    const dafFund = allData.response.DAFFund;
    const clientId = allData.response.ClientID;
    const amount = allData.response.VoucherValue;
    const scannedBarCode = allData.response.CertificateNo;
    const voucherImage = allData.response.VoucherImage;
    const VoucherCode = allData.response.VoucherCode;

    const myobj: any = {
      donorId: donorId,
      campaignId: campaignId,
      organizationId: organizationId,
      pledgeId: pledgeId,
      dafFund: dafFund,
      clientId: clientId,
      amount: VoucherCode,
      scannedBarCode: scannedBarCode,
      voucherImage: voucherImage || "",
    };
    const completeScan = [scannedBarCode];
    this.service.getAllcertificate(completeScan).subscribe((res) => {
      this.checkScanStatus = res[0].Status;
      this.certificatenofordelete = res[0].CertificateId;
    });

    this.service.validateCertificateBatch(myobj).subscribe(
      (response: any) => {
        if (response.success == false) {
          this._toastrService.error(
            "Donor details not found for the selected certificate.",
            "",
            {
              toastClass: "toast ngx-toastr",
              closeButton: true,
              timeOut: 5000,
              disableTimeOut: false,
            }
          );
        } else {
          const certificateNoForArray =
            response.d.vocuherDetail.voucherInfo.voucherNo;
          const saveCoucherCodeArray = [];
          this.certificateItems.forEach((row) => {
            saveCoucherCodeArray.push(row.code);
          });
          if (this.certificateItems.length != 0) {
            if (saveCoucherCodeArray.includes(certificateNoForArray) == true) {
              this._toastrService.error("Certificate already added.", "", {
                toastClass: "toast ngx-toastr",
                closeButton: true,
                timeOut: 5000,
                disableTimeOut: false,
              });
            } else {
              if (this.checkScanStatus == "Complete") {
                this._toastrService.error(
                  "Certificate already processed.",
                  "",
                  {
                    toastClass: "toast ngx-toastr",
                    closeButton: true,
                    timeOut: 5000,
                    disableTimeOut: false,
                  }
                );
              } else {
                this.vocuherStatusBatch =
                  response.d.vocuherDetail.voucherInfo.voucherStatus; //3
                this.vocuherImageStatusBatch =
                  response.d.vocuherDetail.imageRequired; // false
                // this.vocuherImageStatusBatch = false;
                let newItem;

                if (
                  this.vocuherStatusBatch == 3 &&
                  this.vocuherImageStatusBatch == false
                ) {
                  newItem = {
                    status: "Ready to submit",
                    amount: "$" + amount, // Replace with the actual amount from the response
                    code: VoucherCode, // Replace with the actual code from the response
                    warning: false,
                    className: "readyTosubmit",
                  };
                } else if (
                  this.vocuherStatusBatch == 3 &&
                  this.vocuherImageStatusBatch == true
                ) {
                  newItem = {
                    status: "Incomplete DAF",
                    amount: "$" + amount, // Replace with the actual amount from the response
                    code: VoucherCode, // Replace with the actual code from the response
                    warning: true,
                    className: "photoRequire",
                  };
                } else {
                  this._toastrService.error(response.d.message, "", {
                    toastClass: "toast ngx-toastr",
                    closeButton: true,
                    timeOut: 5000,
                    disableTimeOut: false,
                  });
                  return; // Exit early if there's an error
                }
                this.certificateItems.push(newItem);
              }
            }
          } else {
            if (this.checkScanStatus == "Complete") {
              this._toastrService.error("Certificate already processed.", "", {
                toastClass: "toast ngx-toastr",
                closeButton: true,
                timeOut: 5000,
                disableTimeOut: false,
              });
            } else {
              this.vocuherStatusBatch =
                response.d.vocuherDetail.voucherInfo.voucherStatus; //3
              this.vocuherImageStatusBatch =
                response.d.vocuherDetail.imageRequired; // false
              //  this.vocuherImageStatusBatch = true;
              let newItem;

              if (
                this.vocuherStatusBatch == 3 &&
                this.vocuherImageStatusBatch == false
              ) {
                newItem = {
                  status: "Ready to submit",
                  amount: "$" + amount, // Replace with the actual amount from the response
                  code: VoucherCode, // Replace with the actual code from the response
                  warning: false,
                  className: "readyTosubmit",
                };
              } else if (
                this.vocuherStatusBatch == 3 &&
                this.vocuherImageStatusBatch == true
              ) {
                newItem = {
                  status: "Incomplete DAF",
                  amount: "$" + amount, // Replace with the actual amount from the response
                  code: VoucherCode, // Replace with the actual code from the response
                  warning: true,
                  className: "photoRequire",
                };
              } else {
                this._toastrService.error(response.d.message, "", {
                  toastClass: "toast ngx-toastr",
                  closeButton: true,
                  timeOut: 5000,
                  disableTimeOut: false,
                });
                return; // Exit early if there's an error
              }
              this.certificateItems.push(newItem);
            }
          }
        }
      },

      (error) => {
        this._toastrService.error("Invalid Certificate1", "", {
          toastClass: "toast ngx-toastr",
          closeButton: true,
          timeOut: 5000,
          disableTimeOut: false,
        });
      }
    );
  }

  // Add this method to your component
  removeCertificate(index: number) {
    this.certificateItems.splice(index, 1);
    this.certificatearray.splice(index, 1);
  }

  openQRCodeScannerClick() {
    this.manualcertificatentry = false;
    this.secondmanual = true;
  }

  clickecancle() {
    this.paymentaccount = true;
    this.redirectModal = false;
  }
  openchnageaccount() {
    this.paymentaccount = false;
    this.redirectModal = true;
  }
  fetchLocations() {
    const fetchLocationsPayload = {
      userId: "",
      searchText: "",
      searchField: "",
      paging: {
        pageSize: 0,
        pageIndex: 0,
      },
      Id: 0,
      OrganizationId: this.orgid,
      DonorId: 0,
      RefId: this.orgid,
      RefType: "",
      AddressType: "",
      Address1: "",
      Address2: "",
      Street: "",
      Apt: "",
      City: "",
      State: "",
      Zip: 0,
      Latitude: "",
      Longitude: "",
      PhoneNumbers: [
        {
          Id: 0,
          RefId: 0,
          RefType: "",
          PhoneType: "",
          PhoneNumber: "",
        },
      ],
    };
    this.service.getLocations(fetchLocationsPayload).subscribe(
      (response) => {
        if (response.success) {
          this.locations = response.d[0]; // Update the locations property with the fetched data
        }
      },
      (error) => {
        // alert("hello");
        console.error("Error fetching locations", error);
      }
    );
  }
  walletsfunrrr() {
    this.changeaddress = false;
    this.successreports = true;
    this.paymentaccount = false;
    this.paymentcheck = false;
  }
  // localstoragtedata = [];
  updateCertificateImageBatchupload() {
    const data = {
      certificateNo: "",
      voucherImage: this.voucherImage,
      status: "",
      certificateId: this.certificatenofordelete,
    };
    if (this.selectedItemIndex !== null) {
      this.service.updateCertificateImageBatchApiupload(data).subscribe(
        (response) => {
          try {
            // alert(JSON.stringify(response))
            let existingData: any[] = JSON.parse(
              localStorage.getItem("ImageBatchA") || "[]"
            );
            existingData.push(response);
            localStorage.setItem("ImageBatchA", JSON.stringify(existingData));
            this.uploadmodal = false;
            if (this.selectedItemIndex !== null) {
              this.certificateItems[this.selectedItemIndex].status =
                "Ready to submit";
              this.certificateItems[this.selectedItemIndex].success = true; // Mark success
              this.certificateItems[this.selectedItemIndex].warning = false; // Remove warning
              this.certificateItems[this.selectedItemIndex].code = 7777;
            }
          }
          catch (error) {
            console.log('error in image processing');

          }
        },
        (error) => {
          console.error("Error updating certificate image batch:", error);
        }
      );
    }
  }
  selectedItemIndex: number | null = null;
  opemmodalupload(index: number) {
    this.uploadmodal = true;
    this.selectedItemIndex = index;
  }

  singlarray: any;
  batchdata: any;
  getbactdatafromlocalstorage() {
    const batchdata = localStorage.getItem("ImageBatchA");
    const batchdatastorage = JSON.parse(batchdata);
    // console.log('batchdatastorage', batchdatastorage);

    if (Array.isArray(batchdatastorage)) {
      this.batchdata = batchdatastorage.map((connection) => {
        return connection.response; // Ensure you're returning the response
      });
    }
  }

  Voucherdata: any;
  processingscreen: any;
  // loadingprocess:any;
  loadingprocess: boolean = false;
  allcertificate: any;
  isdisabled: boolean = true;
  failedCertificatesLength: any;
  successcertificate: any;
  failddata: any;
  sucess: any;
  faildcertificatecode: any[] = [];
  VoucherImage: any;
  currentUserCirtificateObjectdata: any;

  // payload variable
  DepositToBankAccountId: any;
  ClientAddressID: any;
  ForClientID: any;
  ForAccountType: any;
  AddressType: any;
  AddressTitle: any;
  HouseNoNum: any;
  StreetName: any;
  City: any;
  State: any;
  PostalCode: any;
  FullAddressLine1: any;
  FullAddressLine1Static: any;
  FullAddressLine1_no_suite: any;
  FullAddressLine2: any;
  FullAddress: any;
  FullAddress1Line: any;
  FullAddress3Lines: any;
  FullAddressWithName: any;
  UseACH: any;
  dataAccountNew: any;
  certificateDisable: boolean;
  getBasicDetailsAsync(obj: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.service.getBasicDetails(obj).subscribe({
        next: (res) => resolve(res),
        error: (err) => reject(err)
      });
    });
  }
  async onchecksubmit(data: string) {

    if (data === 'check') {
      this.certificateDisable = true;

      let obj = {
        "OrganizationId": this.orgid
      };
      try {
        // Get basic details
        const basicDetails = await this.getBasicDetailsAsync(obj);
        // Fetch user details using the tax ID
        this.dataAccountNew = await this.service.getUserDetailsByTaxID(basicDetails.d.TaxId).toPromise();
        console.log('Data received:', this.dataAccountNew);
        // Perform operations with this.dataAccountNew
        this.dataaccount[0] = this.dataAccountNew[0];
        console.log('dataaccount:', this.dataaccount);
      } catch (error) {
        console.error('Error:', error);
      }


      // this.service.send_taxid.subscribe(async(data) => {
      //   this.orgdata = data;
      //   // alert(JSON.stringify(this.orgdata));
      //   try {
      //     this.dataAccountNew = await this.service.getUserDetailsByTaxID(this.orgdata.TaxId).toPromise();
      //     console.log('Data received:', this.dataAccountNew);
      //     // Perform any operations with this.dataAccountNew here
      //   } catch (error) {
      //     console.error('tax ID Not Found:', error);
      //   }
      // })

      console.log('this.dataaccountNew', this.dataAccountNew);
      this.dataaccount[0] = this.dataAccountNew[0];
      console.log("dataaccountNew", this.dataaccount);

      // alert(JSON.stringify(this.dataaccount))
      if (this.dataaccount) {
        if (this.dataaccount[0] && !('ClientAddresses' in this.dataaccount[0]) || !Array.isArray(this.dataaccount[0]?.ClientAddresses)) {
          this.showToast("Client Addresses not found ", "error");
          return;
        } else {
          this.DepositToBankAccountId = 0;
          this.ClientAddressID = this.dataaccount[0].ClientAddresses[0].ClientAddressID;
          this.ForClientID = this.dataaccount[0].ClientAddresses[0].ForClientID;
          this.ForAccountType = this.dataaccount[0].ClientAddresses[0].ForAccountType;
          this.AddressType = 'Home';
          this.AddressTitle = "Correspondence";
          this.HouseNoNum = this.dataaccount[0].ClientAddresses[0].HouseNoNum;
          this.StreetName = this.dataaccount[0].ClientAddresses[0].StreetName;
          this.City = this.dataaccount[0].ClientAddresses[0].City;
          this.State = this.dataaccount[0].ClientAddresses[0].State;
          this.PostalCode = this.dataaccount[0].ClientAddresses[0].PostalCode;
          this.FullAddressLine1 = this.dataaccount[0].ClientAddresses[0].FullAddress1Line;
          this.FullAddressLine1Static = this.dataaccount[0].ClientAddresses[0].FullAddressLine1Static
          this.FullAddressLine1_no_suite = this.dataaccount[0].ClientAddresses[0].FullAddressLine1_no_suite;
          this.FullAddressLine2 = this.dataaccount[0].ClientAddresses[0].FullAddressLine2;
          this.FullAddress = this.dataaccount[0].ClientAddresses[0].FullAddress;
          this.FullAddress1Line = this.dataaccount[0].ClientAddresses[0].FullAddress1Line;
          this.FullAddress3Lines = this.dataaccount[0].ClientAddresses[0].FullAddress3Lines;
          this.FullAddressWithName = this.dataaccount[0].ClientAddresses[0].FullAddressWithName;
          this.UseACH = false;
        }
      }
    }
    if (data === 'ACH') {
      this.certificateDisable = true;

      this.DepositToBankAccountId = this.selectedAccID;
      this.ClientAddressID = 0;
      this.ForClientID = 0;
      this.ForAccountType = 0;
      this.AddressType = 'Home';
      this.AddressTitle = "";
      this.HouseNoNum = "";
      this.StreetName = "";
      this.City = "";
      this.State = "";
      this.PostalCode = "";
      this.FullAddressLine1 = "";
      this.FullAddressLine1Static = "";
      this.FullAddressLine1_no_suite = "";
      this.FullAddressLine2 = "";
      this.FullAddress = "";
      this.FullAddress1Line = "";
      this.FullAddress3Lines = "";
      this.FullAddressWithName = "";
      this.UseACH = false;
      this.loadingprocess = false;
    }
    // this.currentUserCirtificateObjectdata = this.service.getvalidatecirtificateDatafromLocalstorage();
    // const allData = JSON.parse(this.currentUserCirtificateObjectdata);
    // console.log('allData.response', this.certificatearray);
    const checkobj = {
      OrgAPIKey: "1",
      Certificates: this.certificatearray.map((row) => ({
        ScannedBarcode: row.response.CertificateNo,
        VoucherNumber: row.response.VoucherCode,
        ClientID: row.response.ClientID,
        VoucherValue: row.response.VoucherValue,
        VoucherImage: row.response.VoucherImage,
        VoucherImageBytes: "",
      })),
      ReturnJson: true,
      DepositToBankAccountID: this.DepositToBankAccountId,
      MailToAddress: {
        ClientAddressID: this.ClientAddressID,
        ForClientID: this.ForClientID,
        ForAccountType: this.ForAccountType,
        AddressType: this.AddressType,
        IsPOBox: false,
        IsMailingAddress: true,
        IsBooksAddress: true,
        AddressTitleShowOption: 0,
        AddressTitle: this.AddressTitle,
        HouseNoNum: this.HouseNoNum,
        HouseNoSuffix: "",
        StreetName: this.StreetName,
        SuiteNo: "",
        City: this.City,
        State: this.State,
        PostalCode: this.PostalCode,
        PlusFour: "",
        Country: "",
        FullAddressLine1: this.FullAddressLine1,
        FullAddressLine1Static: this.FullAddressLine1Static,
        FullAddressLine2Static: "",
        FullAddressLine1_no_suite: this.FullAddressLine1_no_suite,
        FullAddressLine2: this.FullAddressLine2,
        AllowSave: true,
        FullAddress: this.FullAddress,
        FullAddress1Line: this.FullAddress1Line,
        FullAddress3Lines: this.FullAddress3Lines,
        FullAddressWithName: this.FullAddressWithName,
        "DontUse": true,
        "ForAccount": {
          "ObjectState": 0,
          "IsActive": false,
          "IsSelected": false,
          "AllowSave": false,
          "Comment": "sdf",
          "IsFrozen": false,
          "InEditMode": false,
          "Display": "werw"
        },
        "ObjectState": 0,
        "IsActive": true,
        "IsSelected": false,
        "Comment": "",
        "IsFrozen": false,
        "InEditMode": false,
        "Display": "BOL.ClientAddress"
      },
      UseACH: this.UseACH,
    };
    this.checkconfirm = false;
    this.loadingprocess = true;
    this.successreports = false;
    this.paymentcheck = false;
    this.paymentaccount = false;
    this.totalcertificate = true;
    this.onchecksubmitmodal = false;
    // setTimeout(() => {
    this.totalcertificate = true;
    // console.log("checkobj", checkobj);
    this.service.processcertificates(checkobj).subscribe(
      (res) => {
        this.Voucherdata = res;
        this.failddata = this.Voucherdata.result.FailedCertificates;
        this.sucess = this.Voucherdata.result.ProcessedCertificates;
        this.paymentcheck = false;
        this.totalcertificate = true;
        this.failedCertificatesLength =
          this.Voucherdata.result.FailedCertificates.length;
        this.successcertificate =
          this.Voucherdata.result.ProcessedCertificates.length;
        const total = this.failedCertificatesLength + this.successcertificate;
        this.allcertificate = total;
        // console.log('this.Voucherdata', this.Voucherdata);
        localStorage.removeItem("ImageBatchA");
        let items;
        // Move the mapping logic inside the subscribe block
        if (
          this.failedCertificatesLength !== null &&
          Array.isArray(this.failddata) &&
          this.failddata.length > 0
        ) {
          this.failddata.map((data) => {
            console.log("map", data);
            if (data.VoucherInfo == null) {
              const updateobj = {
                certificateId: 0,
                certificateNo: data.ScanRequest.ScannedBarcode,
                voucherImage: "",
                status: "Incomplete DAF",
              };
              console.log("JSON.stringify", JSON.stringify(updateobj));
              this.service.UpdateCertificateImageBatch(updateobj).subscribe(
                (res) => {
                  (items = data.ScanRequest.ScannedBarcode),
                    this.faildcertificatecode.push(items);
                  console.log("res", res);
                },
                (error) => {
                  console.error("Error processing certificate:", error);
                }
              );
            }
          });
        }
        if (
          this.successcertificate !== null &&
          Array.isArray(this.sucess) &&
          this.sucess.length > 0
        ) {
          this.sucess.map((data) => {
            console.log("map", data);
            if (
              data.VoucherInfo !== null &&
              data.VoucherInfo.VoucherStatus == 3
            ) {
              const updateobj = {
                certificateId: 0,
                certificateNo: data.ScanRequest.ScannedBarcode,
                voucherImage: "",
                status: "Complete",
              };
              console.log("JSON.stringify", JSON.stringify(updateobj));
              this.service.UpdateCertificateImageBatch(updateobj).subscribe(
                (res) => {
                  items = data.ScanRequest.ScannedBarcode;
                  this.faildcertificatecode.push(items);
                  console.log("res", res);
                  this.orgnasitionId = this.service.getOrganizationIdFromLocalStorage();
                  this.service
                    .validateSavedCertificates(
                      this.orgnasitionId,
                      updateobj.certificateNo
                    )
                    .subscribe((response: any) => {
                      if (response.success) {
                        let newObj = {
                          certificateId: response.response.CertificateId,
                          DonorId: response.response.DonorId,
                          CampaignId: response.response.CampaignId,
                          OrganizationId: response.response.OrganizationId,
                          Amount: response.response.VoucherValue,
                          certificateNo: response.response.CertificateNo
                        };
                        // console.log("NewOBJ1===>"+JSON.stringify(newObj));

                        this.service
                          .addBatchDonationapi(newObj)
                          .subscribe((response: any) => {
                            if (response.success) {
                              console.log("res", response);
                            }
                          });

                        // console.log("NewOBJ===>"+JSON.stringify(newObj));

                        // localStorage.setItem("validateSavedCertificatesBatchData", JSON.stringify(response));
                      }
                    });
                },
                (error) => {
                  console.error("Error processing certificate:", error);
                }
              );
              //
              // orgnasitionId

              //
            } else {
              const updateobj = {
                certificateId: 0,
                certificateNo: data.ScanRequest.ScannedBarcode,
                voucherImage: "",
                status: "Incomplete DAF",
              };
              console.log("JSON.stringify", JSON.stringify(updateobj));
              this.service.UpdateCertificateImageBatch(updateobj).subscribe(
                (res) => {
                  items = data.ScanRequest.ScannedBarcode;
                  this.faildcertificatecode.push(items);
                  console.log("res", res);
                },
                (error) => {
                  console.error("Error processing certificate:", error);
                }
              );
            }
          });
        }
        this.loadingprocess = false;
        // alert(JSON.stringify(this.faildcertificatecode.push(items)))
        console.log(
          "this.faildcertificatecode.push(items)",
          this.faildcertificatecode
        );
      },
      (error) => {
        console.error("Error updating certificate image batch:", error);
      }
    );

    // }, 1000000);
  }
  reports: any;
  completedata: any[] = [];
  incomplete: any[] = [];
  succescount: any[] = [];
  errorcount: any[] = [];
  pricetotal: any[] = [];
  pricetotalerror: any[] = [];
  opensuccessreport() {
    this.service
      .getAllcertificate(this.faildcertificatecode)
      .subscribe((res) => {
        this.reports = res;
        this.reports.map((data) => {
          if (data.Status == "Complete") {
            this.succescount.push(data);
            this.pricetotal.push(data.VoucherValue);
            // console.log('this.succescount', this.succescount);
            this.completedata.push(data.CertificateNo);
            // console.log('this.completedata', this.completedata);

            //  alert(JSON.stringify(this.complete ))
          }
          if (data.Status == "Incomplete DAF") {
            this.errorcount.push(data);
            this.pricetotalerror.push(data.VoucherValue);
            // console.log('this.errorcount', this.errorcount);

            this.incomplete.push(data.CertificateNo);
            // console.log('this.incomplete', this.incomplete);

            //  alert(JSON.stringify(this.incomplete ))
          }
        });
      });
    if (this.loadingprocess == false) {
      this.totalcertificate = false;
      this.successreports = true;
    }

  }
  pricetotalprace: any;
  errortotaprice: any;
  viewreports() {
    //  alert(JSON.stringify(this.faildcertificatecode));
    this.successreports = false;
    this.totalreports = true;
    this.pricetotalprace = this.calculateTotalsucces();
    this.errortotaprice = this.calculateTotalerror();
  }
  calculateTotalsucces(): number {
    return this.pricetotal.reduce((total, item) => total + item, 0);
  }
  calculateTotalerror(): number {
    // return this.pricetotalerror.reduce((total, item) => {
    //   const amount = item;
    //   return total + amount;
    // }, 0);
    return this.pricetotalerror.reduce((total, item) => total + item, 0);
  }
  alerthlo() {
    window.location.reload();
  }
  cellPhoneExists: any;
  checkValuesExist() {
    const cellPhone = this.newdonorform.controls.Cellphone.value;
    this.cellPhoneExists = this.PrimaryContact.includes(cellPhone);
    // console.log('this.PrimaryContact',this.PrimaryContact);
    return this.cellPhoneExists;
  }

  workPhoneExist() {
    const homephoneExists = this.newdonorform.controls.Homephone.value;
    this.homephoneExists = this.PrimaryContact.includes(homephoneExists);
    // console.log('this.PrimaryContact',this.PrimaryContact);
    return this.homephoneExists;
  }

  emaildata: any;
  emailExist() {
    const Email = this.newdonorform.controls.Email.value;
    this.emaildata = this.emailexist.includes(Email);
    // console.log('this.emaildata',this.emaildata);
    return this.emaildata;
  }
  selectedBankNameACC: any;
  selectedAccID: any;
  onAccountChange(data) {
    const selectedAccount = this.userBankaccountDetails.find(account => account.AccNum === data);
    if (selectedAccount) {
      this.selectedBankNameACC = selectedAccount.BankName;
      this.selectedAccID = selectedAccount.AccID;
    }
  }
  onInputChange(event: any) {
    const input = event.target.value;
    event.target.value = input.replace(/[^a-zA-Z0-9]/g, '');
  }
  resetVariables() {
    this.certificateItems = [];
    this.orgnasitionId = '';
    this.orgid = 0;
    this.donorsList = [];
    this.filteredDonors = [];
    this.locations = "";
    this.userBankaccountDetails = [];

  }
  onInput(event: Event): void {
    const input = event.target as HTMLInputElement;
    input.value = input.value.replace(/\D/g, '');
    if (input.value.length > 3) {
      input.value = input.value.slice(0, 4);
    }
  }

  openmodalcheckconfirm() {
    this.checkconfirm = true;
    this.paymentcheck = false;

  }

  // Function to get formatted number as ***1111
formatCardNumber(number: string): string {
  // Remove spaces and extract the last 4 digits
  const lastFourDigits = number.replace(/\s+/g, '').slice(-4);
  
  // Return the formatted string with *** prepended
  return `***${lastFourDigits}`;
}




}

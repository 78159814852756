import { Component, OnInit, OnDestroy, ViewChild, HostListener, ViewEncapsulation, ElementRef } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

import { Subject } from 'rxjs';
import { take, takeUntil, filter } from 'rxjs/operators';


import { CoreConfigService } from '@core/services/config.service';
import { CoreMenuService } from '@core/components/core-menu/core-menu.service';
import { CoreSidebarService } from '@core/components/core-sidebar/core-sidebar.service';
import { DataService } from 'app/shared/services/data.service';
import { AuthenticationService } from 'app/shared/services';
import { environment } from 'environments/environment';
import { SocialAuthService } from '@abacritt/angularx-social-login';

@Component({
  selector: 'vertical-menu',
  templateUrl: './vertical-menu.component.html',
  styleUrls: ['./vertical-menu.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class VerticalMenuComponent implements OnInit, OnDestroy {
  coreConfig: any;
  menu: any;
  isCollapsed: boolean;
  isScrolled: boolean = false;
  userName: string='';
  currentUserOrgId:number;
  currentUserOrgName:string;

  taxidvar:any;
  orgdata:any;
  url:any;
  orgname:any;

  // Private
  private _unsubscribeAll: Subject<any>;

  /**
   * Constructor
   *
   * @param {CoreConfigService} _coreConfigService
   * @param {CoreMenuService} _coreMenuService
   * @param {CoreSidebarService} _coreSidebarService
   * @param {Router} _router
   */
  constructor(
    private _coreConfigService: CoreConfigService,
    private _coreMenuService: CoreMenuService,
    private _coreSidebarService: CoreSidebarService,
    private _authenticationService: AuthenticationService,
    private _router: Router,
    private service: DataService,
    private social: SocialAuthService,
    private elementRef: ElementRef
  ) {
    // Set the private defaults
    this._unsubscribeAll = new Subject();
  }


  // Lifecycle Hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On Init
   */
  // ngOnInit(): void {
  //   this.currentUserOrgName= "";
  //   // Subscribe config change
  //   this._coreConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe(config => {
  //     this.coreConfig = config;
  //   });

  //   this.isCollapsed = this._coreSidebarService.getSidebarRegistry('menu').collapsed;

  //   // Close the menu on router NavigationEnd (Required for small screen to close the menu on select)
  //   this._router.events
  //     .pipe(
  //       filter(event => event instanceof NavigationEnd),
  //       takeUntil(this._unsubscribeAll)
  //     )
  //     .subscribe(() => {
  //       if (this._coreSidebarService.getSidebarRegistry('menu')) {
  //         this._coreSidebarService.getSidebarRegistry('menu').close();
  //       }
  //     });

  //   // scroll to active on navigation end
  //   this._router.events
  //     .pipe(
  //       filter(event => event instanceof NavigationEnd),
  //       take(1)
  //     )
  //     .subscribe(() => {
  //       setTimeout(() => {
  //         // this.directiveRef.scrollToElement('.navigation .active', -180, 500);
  //       });
  //     });

  //   // Get current menu
  //   this._coreMenuService.onMenuChanged
  //     .pipe(
  //       filter(value => value !== null),
  //       takeUntil(this._unsubscribeAll)
  //     )
  //     .subscribe(() => {
  //       this.menu = this._coreMenuService.getCurrentMenu();
  //     });
  //     console.log('Abhishek=Menu==', this.menu, localStorage.getItem('currentUser'))
  //     //this.getBasicDetails();
  //     if(localStorage.getItem('currentUser'))
  //       this.userName = JSON.parse(localStorage.getItem('currentUser')).firstName+" "+JSON.parse(localStorage.getItem('currentUser')).lastName;
  //       this.currentUserOrgId = JSON.parse(localStorage.getItem('currentUser')).id;
  //     // alert(this.currentUserOrgId);
  //     this.getOrgDetails();

  //     this.service.update_OrgInfo.subscribe(dta=>{
  //       // console.log('UPDATE DONATION in doner=======', dta)
  //       this.getOrgDetails();
  //     });
      
      
  // }


  ngOnInit(): void {
    this.currentUserOrgName = "";
    // Subscribe config change
    this._coreConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe(config => {
      this.coreConfig = config;
    });
  
    this.isCollapsed = this._coreSidebarService.getSidebarRegistry('menu')?.collapsed;
  
    // Close the menu on router NavigationEnd (Required for small screen to close the menu on select)
    this._router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        takeUntil(this._unsubscribeAll)
      )
      .subscribe(() => {
        const sidebar = this._coreSidebarService.getSidebarRegistry('menu');
        if (sidebar) {
          sidebar.close();
        }
      });
  
    // Scroll to active on navigation end
    this._router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        take(1)
      )
      .subscribe(() => {
        setTimeout(() => {
          // this.directiveRef.scrollToElement('.navigation .active', -180, 500);
        });
      });
  
    // Get current menu
    this._coreMenuService.onMenuChanged
      .pipe(
        filter(value => value !== null),
        takeUntil(this._unsubscribeAll)
      )
      .subscribe(() => {
        this.menu = this._coreMenuService.getCurrentMenu();
      });
  
    console.log('Abhishek=Menu==', this.menu, localStorage.getItem('currentUser'));
  
    // Check if currentUser exists in localStorage and parse it
    const currentUser = localStorage.getItem('currentUser') ? JSON.parse(localStorage.getItem('currentUser')) : null;
    
    // Ensure currentUser is not null before accessing its properties
    if (currentUser) {
      this.userName = `${currentUser.firstName} ${currentUser.lastName}`;
      this.currentUserOrgId = currentUser.id;
    } else {
      console.warn('Current user data is not available in localStorage.');
    }
  
    this.getOrgDetails();
  
    this.service.update_OrgInfo.subscribe(dta => {
      this.getOrgDetails();
    });
  }
  

  /**
   * On Destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  getOrgID(){
    const currentUser = this._authenticationService.currentUserValue;
    if(!currentUser)
      return null
    return currentUser.id;

  }


  getOrgDetails() {
    if(this.currentUserOrgId) {
      let obj = {
        "OrganizationId": this.currentUserOrgId
      }
      this.service.getBasicDetails(obj).subscribe({
        next: (res: any) => {
          if(res.success){
            this.currentUserOrgName = res.d.OrganizationName;
            // let data=res.d;
            // // debugger;
            // this.taxidvar=res.d.TaxId
            // this.orgdata=data;
            // if(data.Image){
            //   this.url=environment.imageUrl+data.Image;
            // }
            // this.orgname=data.OrganizationName;
            // this.myForm.patchValue({
            //   orgdetail: "static"
            // })
          } else {
            this.currentUserOrgName = "";
          }
          // debugger;
        },
        error: (res: any) => {
          // debugger;
        }
  
      })

    } else {

    }
  }


  /*getBasicDetails() {
    if(!this.getOrgID())
      return;
    let obj = {
      "OrganizationId": this.getOrgID()
    }
    this.service.getBasicDetails(obj).subscribe({
      next: (res: any) => {
        //debugger;
        if(res.success){
          let data=res.d;
          this.taxidvar=res.d.TaxId
          this.orgdata=data;
          if(data.Image){
            this.url=environment.imageUrl+data.Image;
          }
          this.orgname=data.OrganizationName;
          // this.myForm.patchValue({
          //   orgdetail: "static"
          // })
        }
        // debugger;
      },
      error: (res: any) => {
        // debugger;
      }

    })
    
  } */

  // Public Methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * On Sidebar scroll set isScrolled as true
   */
  onSidebarScroll(): void {
    // if (this.directiveRef.position(true).y > 3) {
    //   this.isScrolled = true;
    // } else {
    //   this.isScrolled = false;
    // }
  }

  /**
   * Toggle sidebar expanded status
   */
  toggleSidebar(): void {
    this._coreSidebarService.getSidebarRegistry('menu').toggleOpen();
  }

  /**
   * Toggle sidebar collapsed status
   */
  toggleSidebarCollapsible(): void {
    // Get the current menu state
    this._coreConfigService
      .getConfig()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(config => {
        this.isCollapsed = config.layout.menu.collapsed;
      });

    if (this.isCollapsed) {
      this._coreConfigService.setConfig({ layout: { menu: { collapsed: false } } }, { emitEvent: true });
    } else {
      this._coreConfigService.setConfig({ layout: { menu: { collapsed: true } } }, { emitEvent: true });
    }
  }
  logoutFlag = false;
  logoutModel(event: Event) {
    event.stopPropagation(); 
    this.logoutFlag = !this.logoutFlag;
  }
  logout(){
    this._authenticationService.logout();

    this._router.navigate(['/user-account/authentication/login']);
    this.signOut()
  }
  signOut(): void {
    this.social.signOut();
  }
  openDonationPopup(){
    this.service.donor_Donation.next({ DonorId: null });
  }
  

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    if (this.logoutFlag) {
      this.logoutFlag = false;
    }
  }
}

import { NgModule } from '@angular/core';

import { CoreCommonModule } from '@core/common.module';

import { MenuComponent } from 'app/shared/page-layout-module/menu/menu.component';
import { VerticalMenuModule } from 'app/shared/page-layout-module/menu/vertical-menu/vertical-menu.module';

import { NgScrollbarModule, NG_SCROLLBAR_OPTIONS } from 'ngx-scrollbar';

@NgModule({
  declarations: [MenuComponent],
  imports: [CoreCommonModule, VerticalMenuModule,NgScrollbarModule],
  exports: [MenuComponent,NgScrollbarModule]
})
export class MenuModule {}

import {NgModule} from '@angular/core';
import {PortalModule} from '@angular/cdk/portal';
import {ScrollingModule} from '@angular/cdk/scrolling';
import {MatStepperModule} from '@angular/material/stepper';
import { NgSelectModule } from '@ng-select/ng-select';

@NgModule({
  exports: [
    MatStepperModule,
    NgSelectModule,
    PortalModule,
    ScrollingModule,
  ]
})
export class DemoMaterialModule {}